import pdfMake from 'pdfmake/build/pdfmake';
import vfsFonts from 'pdfmake/build/vfs_fonts';

const getTrackingMessage = (coordOmission) => {
    return coordOmission ? [
        {
            table: {
                widths: ['*'],
                body: [
                    [
                        {
                            text: '* El usuario omitió el uso y registro del seguimiento para este traslado.',
                            style: 'noteText',
                            alignment: 'left',
                            fontSize: 10,
                            italics: true
                        }
                    ]
                ]
            },
            layout: 'noBorders',
            margin: [0, 10, 0, 0]
        }
    ] : [];
};

export default async (appointment, t) => {

    pdfMake.vfs = vfsFonts.pdfMake.vfs;

    const documentDefinition = {
        pageSize: 'A4',
        pageOrientation: 'portrait',
        info: {
            author: 'Mobility',
            subject: t("mobility.pdf.title"),
        },
        content: [
            
            {
                text: t("mobility.pdf.title"),
                style: 'header'
            },

            {
                table: {
                    headerRows: 1,
                    widths: ['*', '*', '*', '*'],
                    body: [

                        [
                            { colSpan: 4, fillColor: '#cdcdcd', text: t("Transfer"), style: 'tableSectionTitle', alignment: 'center' },
                            {},
                            {},
                            {}
                        ],
                        [
                            {fillColor: '#F1EFEF', text: t("common.financier"), style: 'label',alignment: 'left' },
                            { text: appointment.financier.financier, style: 'value', alignment: 'left' },
                            {fillColor: '#F1EFEF',  text: t('label.provider'), style: 'label', alignment: 'left' },
                            { text: appointment.financier.provider, style: 'value', alignment: 'left' },
                        ],
                        [
                            {fillColor: '#F1EFEF', text: t('address.number'), style: 'label', alignment: 'left' },
                            { text: appointment.appointmentId, style: 'value', alignment: 'left' },
                            {fillColor: '#F1EFEF', text: t('address.type'), style: 'label', alignment: 'left' },
                            { text: appointment.trasladoName, style: 'value', alignment: 'left' },
                        ],
                        [
                            { colSpan: 4, fillColor: '#cdcdcd', text: t('common.patient'), style: 'tableSectionTitle', alignment: 'center' },
                            {},
                            {},
                            {}
                        ],
                        [
                            {fillColor: '#F1EFEF', colSpan: 1, text: t('appointment.table.customer.lastname'), style: 'label', alignment: 'left' },
                            { colSpan: 3, text: appointment.customer.lastName || '-', style: 'value', alignment: 'left' },
                            {},
                            {}
                        ],
                        [
                            { fillColor: '#F1EFEF',colSpan: 1, text: t('customers.input.first_name'), style: 'label', alignment: 'left' },
                            { colSpan: 3, text: appointment.customer.firstName || '-', style: 'value', alignment: 'left' },
                            {},
                            {}
                        ],
                        [
                            {fillColor: '#F1EFEF', text: t('technicalfile.docType'), style: 'label', alignment: 'left' },
                            { text: appointment.customer.typeDocument || '-', style: 'value', alignment: 'left' },
                            {fillColor: '#F1EFEF', text: t('technicalfile.docNumber'), style: 'label', alignment: 'left' },
                            { text: appointment.customer.document || '-', style: 'value', alignment: 'left' },
                        ],


                        [
                            { colSpan: 4, fillColor: '#cdcdcd', text: t('label.driver'), style: 'tableSectionTitle', alignment: 'center' },
                            {},
                            {},
                            {}
                        ],
                        [
                            {fillColor: '#F1EFEF', colSpan: 1, text: t('appointment.table.customer.lastname'), style: 'label', alignment: 'left' },
                            { colSpan: 3, text: appointment.chofer.lastName || '-', style: 'value', alignment: 'left' },
                            {},
                            {}
                        ],
                        [
                            {fillColor: '#F1EFEF', colSpan: 1, text: t('customers.input.first_name'), style: 'label', alignment: 'left' },
                            { colSpan: 3, text: appointment.chofer.firstName || '-', style: 'value', alignment: 'left' },
                            {},
                            {}
                        ],
                        [
                            {fillColor: '#F1EFEF', text: t('technicalfile.docType'), style: 'label', alignment: 'left' },
                            { text: appointment.chofer.typeDocument || '-', style: 'value', alignment: 'left' },
                            {fillColor: '#F1EFEF', text: t('technicalfile.docNumber'), style: 'label', alignment: 'left' },
                            { text: appointment.chofer.document || '-', style: 'value', alignment: 'left' },
                        ],

                        [
                            { colSpan: 4, fillColor: '#cdcdcd', text: t('mobility.pdf.begginning'), style: 'tableSectionTitle', alignment: 'center' },
                            {},
                            {},
                            {}
                        ],
                        [
                            {fillColor: '#F1EFEF', colSpan: 2, text: t('mobility.pdf.start.programmed.direction'), style: 'label', alignment: 'left' },
                            {},
                            { colSpan: 2, text: appointment.customer.address, style: 'value', alignment: 'left' },
                            {}
                        ],
                        [
                            {fillColor: '#F1EFEF', text: t('address.location'), style: 'label', alignment: 'left' },
                            { text: appointment.customer.location || '-', style: 'value', alignment: 'left' },
                            {fillColor: '#F1EFEF', text: t('address.province'), style: 'label', alignment: 'left' },
                            { text: appointment.customer.province || '-', style: 'value', alignment: 'left' },
                        ],
                        [
                            {fillColor: '#F1EFEF', colSpan: 2, text: t('mobility.pdf.trip.start.location'), style: 'label', alignment: 'left' },
                            {},
                            { colSpan: 2, text: appointment.customer.address || '-', style: 'value', alignment: 'left' },
                            {}
                        ],
                        [
                            {fillColor: '#F1EFEF', text: t('address.location'), style: 'label', alignment: 'left' },
                            { text: appointment.customer.location || '-', style: 'value', alignment: 'left' },
                            {fillColor: '#F1EFEF', text: t('address.province'), style: 'label', alignment: 'left' },
                            { text: appointment.customer.province || '-', style: 'value', alignment: 'left' },
                        ],
                        [
                            {fillColor: '#F1EFEF', text: t('common.date'), style: 'label', alignment: 'left' },
                            { text: appointment.startDate, style: 'value', alignment: 'left' },
                            {fillColor: '#F1EFEF', text: t('common.hour'), style: 'label', alignment: 'left' },
                            { text: appointment.startTime, style: 'value', alignment: 'left' },
                        ],
                        [
                            {fillColor: '#F1EFEF', text: t('mobility.pdf.certificate.start'), style: 'label', alignment: 'left' },
                            { text: 'Si', style: 'value', alignment: 'left', colSpan:3 },
                            {},
                            {}
                        ],
                        [
                            { colSpan: 4, fillColor: '#cdcdcd', text: t('common.end'), style: 'tableSectionTitle', alignment: 'center' },
                            {},
                            {},
                            {}
                        ],
                        [
                            {fillColor: '#F1EFEF', colSpan: 2, text: t('mobility.pdf.destination.programmed.direction'), style: 'label', alignment: 'left' },
                            {},
                            { colSpan: 2, text: appointment.nosocomio.address, style: 'value', alignment: 'left' },
                            {}
                        ],
                        [
                            {fillColor: '#F1EFEF', text: t('address.location'), style: 'label', alignment: 'left' },
                            { text: appointment.nosocomio.location, style: 'value', alignment: 'left' },
                            {fillColor: '#F1EFEF', text: t('address.province'), style: 'label', alignment: 'left' },
                            { text: appointment.nosocomio.province, style: 'value', alignment: 'left' },
                        ],
                        [
                            {fillColor: '#F1EFEF', colSpan: 2, text: t('mobility.pdf.trip.end.location'), style: 'label', alignment: 'left' }, {},
                            { colSpan: 2, text: appointment.address_fin, style: 'value', alignment: 'left' }, {}
                        ],
                        [
                            {fillColor: '#F1EFEF', text: t('address.location'), style: 'label', alignment: 'left' },
                            { text: appointment.nosocomio.location, style: 'value', alignment: 'left' },
                            {fillColor: '#F1EFEF', text: t('address.province'), style: 'label', alignment: 'left' },
                            { text: appointment.nosocomio.province, style: 'value', alignment: 'left' }
                        ],
                        [
                            {fillColor: '#F1EFEF', text: t('common.date'), style: 'label', alignment: 'left' },
                            { text: appointment.realDate, style: 'value', alignment: 'left' },
                            {fillColor: '#F1EFEF', text: t('common.hour'), style: 'label', alignment: 'left' },
                            { text: appointment.realTime, style: 'value', alignment: 'left' },
                        ],
                        [
                            {fillColor: '#F1EFEF', text: t('mobility.pdf.certificate.end'), style: 'label', alignment: 'left' },
                            { text: 'Si', style: 'value', alignment: 'left', colSpan:3 },
                            {},
                            {}
                        ],
                        [
                            { colSpan: 4, fillColor: '#cdcdcd', text: 'Trayecto', style: 'tableSectionTitle', alignment: 'center' },
                            {},
                            {},
                            {}
                        ],
                        [
                            {fillColor: '#F1EFEF', text: 'Distancia recorrido', style: 'label', alignment: 'left' },
                            { text: appointment.trayecto.distance, style: 'value', alignment: 'left' },
                            {fillColor: '#F1EFEF', text: t('modules.duration.title'), style: 'label', alignment: 'left' },
                            { text: appointment.trayecto.duration, style: 'value', alignment: 'left' },
                        ],

                    ],
                }
            },
            ...getTrackingMessage(appointment.trayecto.coordOmission)
        ],
        styles: {
            rightme:
            {   
                alignment: 'right'
            },
            header: {
                fontSize: 25,
                alignment: 'center',
                margin: [0, 20, 0, 50]
            },
            tableSectionTitle: {
                fontSize: 14,
                bold: true,
            },
            value: {
                fontSize: 12,
            },
            label: {
                fontSize: 12,
                bold: true
            },
            qr: {
                alignment: 'center',
                margin: [0, 50, 0, 50]
            },

        }
    };
    

    pdfMake.createPdf(documentDefinition).download(`traslado-${appointment.appointmentId}.pdf`);

}