import { Theme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles<Theme, { color: string }>((_theme) => ({
    container: {
        padding: '0.8rem',
        backgroundColor: ({ color }) => color, 
        color: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height:"auto",
        gap: '0.5rem',
        borderRadius: "5px",
        fontWeight: 600,
        textAlign: "center",
        marginTop: "0.8rem",
        marginBottom:"0.8rem",
        '& .MuiSvgIcon-root': {
            margin: 0
        }
    }
}));

export default useStyles;