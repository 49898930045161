import './Users.css';

import React, { Component } from 'react';

import Add from '@material-ui/icons/Add';
import Authority from '../../util/Authority';
import Button from '../CustomButtons/Button';
import ButtonSpinner from '../ButtonSpinner';
import Card from '../Card/Card';
import CardBody from '../Card/CardBody';
import CardHeader from '../Card/CardHeader';
import CardIcon from '../Card/CardIcon';
import ComponentDisabledWithTooltip from '../ComponentDisabledWithTooltip';
import CustomInput from '../CustomInput/CustomInput';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import PeopleIcon from '@material-ui/icons/People';
import PersonApiInvoker from '../../api/PersonApiInvoker';
import PropTypes from 'prop-types';
import SelectInput from '../SelectInput';
import Snackbar from '../Snackbar/Snackbar';
import Table from '../Table/Table';
import { Tooltip } from '@material-ui/core';
import { browserHistory } from 'react-router';
import { withTranslation } from 'react-i18next';

class Users extends Component {
    constructor(props) {
        super(props);
        const { t } = this.props;

        const roles = [
            { id: 'ADM', value: t('common.role.adm') },
            { id: 'GTE', value: t('common.role.gte') },
            { id: 'DRI', value: t('common.role.chf') },
            { id: 'COG', value: t('common.role.cog') },
            { id: 'COP', value: t('common.role.cop') },
            { id: 'COM', value: t('common.role.com') },
            { id: 'PROF', value: t('common.role.prof') },
            { id: 'PAC', value: t('common.role.pac') },
        ];

        const status = [
            { id: 'active', value: t('active') },
            { id: 'pending', value: t('common.pending') },
            { id: 'inactive', value: t('common.deactive') }
        ];

        this.state = {
            loading: false,
            loadingSearch: false,
            users: [],
            filter: {lastName: "", firstName: ""},
            pageSize: 12,
            page: 0,
            alertColor: 'info',
            alertMessage: '',
            alertOpen: false,
            roles,
            status,
            isPatientActive: Authority.hasPermission("invite_patient"),
            isShowMore: true,
        }
    }

    componentWillMount() {
        this.getUsersData();
    }

    formatUsers = (data) => {
        const { users } = this.state;
        const newUsers = data.map(c => {
            return {
                ...c,
                rolName:c.rolName === 'DRI' ? 'CHOF' : c.rolName,
                typeStatus: c.status,
                actions: c.rolName == 'PAC' && 
                    (c.invitationSent == null || c.invitationSent == false) && c.status != 'pending' ? this.renderActions(c) : <div/>
            }
        });

        const isShowMore = (newUsers.length > 0) || (newUsers.length < this.pageSize *2);

        this.setState({ isShowMore });
        const updateUsers = [...users, ...newUsers];
        return updateUsers;
    };

    sendInvitation = (user) => {
        const { t } = this.props;
        this.setState({ save: true, busy: true });
        PersonApiInvoker.postInvitation({
            firstName: "",
            lastName: "",
            mail: user.mail,
            practiceList: [],
            rolId: 7,
        }, () => {
            this.openAlert('success', t('users.form.success'));
            this.setState({ busy: false, save: false });
            this.filtersSearch();
        }, error => {
            if (error.status === 400) {
                this.setState({
                    isEmailAlreadyRegistred: true,
                    messageError: error.message,
                    busy: false,
                    save: false,
                });
                return;
            }
            const message = error.message ?? error;
            this.openAlert('danger', message + '');
            this.setState({ busy: false, save: false });
        });

    }

    openAlert(alertColor, alertMessage) {
        this.setState({
            alertColor,
            alertMessage,
            alertOpen: true,
        });

        setTimeout(() => {
            this.setState({ alertOpen: false });
            if (alertColor === 'success') {
                browserHistory.push('/users');
                this.cleanFields();
            }
        }, 5000);
    }


    renderStatus = (type,row) => {
        const { t } = this.props;
        let confirmed = <span className="status-tag active">{
            row.status == 'active' ? t('common.active.lowercase') : t('common.inactive.lowercase')}</span>;
        return (type === 'Employee' || type === 'Customer') ?
            confirmed :
        <span className="status-tag pending">{t('common.pending.lowercase')}</span>
    }

    renderActions = (c) => {
        const { t } = this.props;

        return (
            <div className="appointments-actions">
              <Tooltip title={t('customer.invite')}>
                <span>
                  <Button
                    simple
                    justIcon
                    color="primary"
                    authority="invite_patient"
                    onClick={() => {
                        this.sendInvitation(c);
                    }}
                  ><LocalHospitalIcon /></Button>
                </span>
              </Tooltip>
        </div>);
    }

    getUsersData = () => {
        this.setState({ loading: true });
        const self = this;
        let params = Object.keys(this.state.filter).map((k) => {
            let value = self.state.filter[k];
            if (value !== '') {
                return `${encodeURIComponent(k)}=${encodeURIComponent(value)}`;
            } else {
                return null;
            }
        }).join('&')
        if (params !== '') {
            params = `${params}&`;
        }
        params = `${params}page=${this.state.page}&pageSize=${this.state.pageSize}`;

        PersonApiInvoker.getUsers(params, data => {
            const users = this.formatUsers(data);
            this.setState({ users, loading: false, loadingSearch: false });
        }, error => {
            console.error('** error getUsers', error);
            this.setState({ loading: false, loadingSearch: false });
        });
    }

    showMore = () => {
        this.setState((prevState) => ({ page: prevState.page + 1, loading: true }), () => {
          this.getUsersData();
        });
    }

    addUser = () => browserHistory.push('new-user');

    cleanSearch = () => {
        this.setState({
            users: [],
            filter: { firstName: "", lastName: ""},
            page: 0,
            isShowMore: true,
            loadingSearch: false,
        }, () => {
            this.getUsersData();
        });
    }

    filtersSearch = () => {
        this.setState({
            users: [],
            page: 0,
            isShowMore: true,
            loadingSearch: true,
        }, () => {
            this.getUsersData();
        })
    }

    handleValue = (value, type) => {
        const { filter } = this.state;
        const newFilter = filter;
        newFilter[type] = value;
        this.setState({ filter: newFilter });
    }

    renderTableHeader = () => {
        const { t } = this.props;
        const tableHead = [
            { Header: t('users.view.lastName'), accessor: 'lastName' },
            { Header: t('users.view.firstName'), accessor: 'firstName' },
            { Header: t('users.view.email'), accessor: 'mail' },
            { Header: t('users.view.role'), accessor: 'rolName' },
            { Header: t('users.view.status'),
                id: 'status',
                accessor: row => this.renderStatus(row.type, row),
                filterMethod: (f, row) => {
                    const value = f.value.toLowerCase();
                    return row.status.props.children.indexOf(value) >= 0;
                }
            },
        ];

        if (this.state.isPatientActive) {
            const actionsTable = { Header: t('actions'), accessor: 'actions' };
            tableHead.push(actionsTable);
        }
        return tableHead;
    }

    render() {
        const { t } = this.props;
        const { loading, loadingSearch, filter, isShowMore, users, roles, status } = this.state;

        return (
            <GridContainer className="users-container">
                <GridItem xs={12}>
                    <Card>
                        <CardHeader icon>
                            <CardIcon color="primary">
                                <PeopleIcon />
                            </CardIcon>
                            <Button
                                id="button-create"
                                className="add-content-button"
                                round
                                color="primary"
                                authority="new_user"
                                onClick={() => this.addUser()}
                            >
                                <Add className="user-button-icon" /> {t('users.create')}
                            </Button>
                        </CardHeader>

                        <CardBody className="padding-top-box">
                            <GridContainer>
                                <GridItem xs={12} sm={9} md={10} className="no-padding">
                                    <GridContainer className="margin-min">
                                        <GridItem xs={6} sm={4} className="margin-min">
                                            <CustomInput
                                                className="filter-full no-padding"
                                                labelText={t('users.view.lastName')}
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                value={filter.lastName}
                                                onChange={(value) => this.handleValue(value.target.value, 'lastName')}
                                                errorText={t('error.required')}
                                            />
                                        </GridItem>
                                        <GridItem xs={6} sm={4} className="margin-min">
                                            <CustomInput
                                                className="filter-full no-padding"
                                                labelText={t('users.view.firstName')}
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                value={filter.firstName}
                                                onChange={(value) => this.handleValue(value.target.value, 'firstName')}
                                                errorText={t('error.required')}
                                            />
                                        </GridItem>
                                        <GridItem xs={6} sm={4} className="margin-min">
                                            <CustomInput
                                                className="filter-full no-padding"
                                                labelText={t('users.view.email')}
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                type={'email'}
                                                value={filter.mail !== undefined ? filter.mail : ''}
                                                onChange={(value) => this.handleValue(value.target.value, 'mail')}
                                                errorText={t('error.required')}
                                            />
                                        </GridItem>
                                        <GridItem xs={6} sm={4} className="margin-min">
                                            <SelectInput
                                                className="no-padding"
                                                label={t('users.view.role')}
                                                elements={roles}
                                                value={filter.rolName}
                                                onSelectedValue={(value) => this.handleValue(value, 'rolName')}
                                            />
                                        </GridItem>
                                        <GridItem xs={6} sm={4} className="margin-min">
                                            <SelectInput
                                                className="no-padding"
                                                label={t('status')}
                                                elements={status}
                                                value={filter.status}
                                                onSelectedValue={(value) => this.handleValue(value, 'status')}
                                            />
                                        </GridItem>
                                    </GridContainer>
                                </GridItem>
                                <GridItem className="filters-actions" xs={12} sm={3} md={2}>
                                    <div>
                                        <ComponentDisabledWithTooltip
                                            tooltipText={t('reassignAppointments.buttonTooltip')}
                                            disabled={false}
                                            loading={false}
                                            component={
                                                <ButtonSpinner
                                                    id="button-search"
                                                    onClick={() => this.filtersSearch()}
                                                    label={t('appointment.find.find')}
                                                    labelLoading={t('appointment.find.find')}
                                                    loading={loadingSearch}
                                                    disabled={loading}
                                                    typeButton="submit"
                                                    color="primary"
                                                    block
                                                />
                                            }
                                        />
                                        <Button id="button-clean" block onClick={() => this.cleanSearch()} color="danger">
                                            {t('appointment.find.clean')}
                                        </Button>
                                    </div>
                                </GridItem>
                            </GridContainer>
                                <Table
                                    striped
                                    filterable
                                    loading={loading}
                                    tableHeaderColor="primary"
                                    tableHead={this.renderTableHeader()}
                                    tableData={users}
                                    defaultPageSize={users.length <= 12 ? 12 : users.length}
                                    showPaginationTop={false}
                                    showPaginationBottom={false}
                                    colorsColls={['primary']}
                                />
                    
                            { users &&
                                <GridItem xs={12} sm={12} md={12}>
                                    <ButtonSpinner
                                        onClick={() => this.showMore()}
                                        label={t('users.showmore')}
                                        labelLoading={t('users.showmore')}
                                        loading={false}
                                        block
                                        disabled={loading || !isShowMore}
                                        className="button-gray"
                                        id="button-more"
                                    />
                                </GridItem>
                            }
                        </CardBody>
                    </Card>
                </GridItem>
                <Snackbar
                        place="tr"
                        color={this.state.alertColor}
                        message={this.state.alertMessage}
                        open={this.state.alertOpen}
                    />
            </GridContainer>
        )
    }
}

Users.propTypes = {
    t: PropTypes.func,
}

export default withTranslation()(Users);
