import { isArray } from "lodash";

// User type enum
const USER_TYPE = {
    PROVIDER: 'PROVIDER',
    FINANCIER: 'FINANCIER'
};

const getAuth = (company) => {
    let auth = company.authorities;
    if (!isArray(company.authorities)) {
        auth = company.authorities.split(",");
    }
    return auth;
}

class Authority {
    

    static hasPermission = (authority) => {
        let result = true;
        let companyStr = localStorage.getItem('company');
        if (!companyStr) {
            return result;
        }
        const company = JSON.parse(companyStr);
        const auth = getAuth(company);

        if (company?.authorities && authority && isArray(auth)) {
            const splitAuthority = authority.split(",")
            for (let item of splitAuthority) {
                try {
                    result = item ? company.authorities.split(",").includes(item) : true;
                } catch (Exception) {
                    return false;
                }
                if (result) {
                    break;
                }
            }
        }
        return result;
    }

    static getUserType = () => {
        const company_partnership_id = localStorage.getItem("company_partnership_id")
        if (company_partnership_id !== null) {
            return USER_TYPE.PROVIDER
        }
        return USER_TYPE.FINANCIER
    }
    static isProviderUser = () => {
        return this.getUserType() === USER_TYPE.PROVIDER
    }
    static isFinancierUser = () => {
        return this.getUserType() === USER_TYPE.FINANCIER 
    }
}

export default Authority;