import "./TransferAppointments.css";

import * as _ from "lodash";

import {
  DeleteDocument,
  HealthCalendar,
  ViewList as ViewListIcon,
} from "../../icons";
import { Grid, debounce } from "@material-ui/core";
import React, { Component } from "react";

import Add from "@material-ui/icons/Add";
import AppointmentApiInvoker from "../../api/AppointmentApiInvoker";
import Assignment from "@material-ui/icons/Assignment";
import Authority from "../../util/Authority";
import BusinessApiInvoker from "../../api/BusinessApiInvoker";
import Button from "../CustomButtons/Button";
import ButtonSpinner from "../ButtonSpinner";
import Card from "../Card/Card";
import CardBody from "../Card/CardBody";
import CardHeader from "../Card/CardHeader";
import CardIcon from "../Card/CardIcon";
import ComponentDisabledWithTooltip from "../ComponentDisabledWithTooltip/ComponentDisabledWithTooltip";
import CustomDialog from "../CustomDialog/index";
import CustomInput from "../CustomInput/CustomInput";
import CustomSweetAlert from "../CustomSweetAlert";
import DateInput from "../DateInput";
import DoneIcon from '@material-ui/icons/Done';
import Edit from "@material-ui/icons/Edit";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import ItemsRequest from "./ItemsRequest";
import PersonApiInvoker from "../../api/PersonApiInvoker";
import PropTypes from "prop-types";
import SelectInput from "../SelectInput/index";
import Snackbar from "../Snackbar/Snackbar.jsx";
import SweetAlert from "react-bootstrap-sweetalert";
import Table from "../Table/Table";
import Tooltip from "@material-ui/core/Tooltip";
import ValidationInput from "../ValidationInput";
import { browserHistory } from "react-router";
import moment from "moment";
import { withTranslation } from "react-i18next";
import { actionValidations } from "./utils/validations";

const dateFormat = "DD/MM/YYYY";

class TransferAppointments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appointments: [],
      loading: false,
      showItemRequest: false,
      filterModules: "",
      filterProfessionals: "",
      filterStatus: "",
      filterTools: "",
      filterColors: [
        { id: 1, value: this.props.t("appointment.table.filters.red") },
        { id: 2, value: this.props.t("appointment.table.filters.yellow") },
        { id: 3, value: this.props.t("appointment.table.filters.green") },
        { id: 4, value: this.props.t("appointment.table.filters.rejected") },
      ],
      transferStatus: [
        { id: "CREATED", value: this.props.t("CREATED") },
        /*{
          id: "TRANSFER_SCHEDULES_PARTIALLY_ASSIGNED",
          value: this.props.t("appointment.table.status.PARTIAL_ASSIGNED"),
        },*/
        {
          id: "TRANSFER_SCHEDULES_ASSIGNED",
          value: this.props.t("TRANSFER_SCHEDULES_ASSIGNED"),
        },
        { id: "ENDED", value: this.props.t("ENDED") },
        { id: "CANCELLED", value: this.props.t("status.CANCELLED") },
        { id: "IN_PROGRESS", value: this.props.t("status.EXPIRED") },
        { id: "PARTIAL_ASSIGNED", value: this.props.t("status.PARTIAL_ASSIGNED") },
        
      ],
      dateFrom: "",
      financiers: [],
      dateTo: "",
      currentFilterColor: [],
      color: "true",
      checkedInput: false,
      currentUserId: localStorage.getItem("user_id"),
      dialogCopyApointment: false,
      busy: false,
      filterAppointmentId: "",
      filterCustomer: "",
      filterIdentification: "",
      filterState: "",
      filterFinancier: "",
      filterStartDate: "",
      filterEndDate: "",
      filterDriver: "",
      filterPersonId: "",
      page: 0,
      dataOrigin:[],
      openMessage: false,
      customersOptions: [],
      showSnackBar: false,
      selectedAppointmentId: null,
      selectedAppointment: {},
      snackbar: {
        open: false,
        message: '',
        color: 'info',
        place: 'tc'
      },
      selectedCancelAppointment: null,
      company_partnership_id: localStorage.getItem("company_partnership_id")
    };
    this.myRefCustomerId = React.createRef();
  }

  

  handleCustomerOptions(event) {
    if (event?.target?.value == undefined) {
      return;
    }
    const value = (event?.target?.value);
    const isValue = (event?.target) && (value && value.length >= 3);
    if (!event) {
      this.clearCustomerFieldSearch();
    }
    if (isValue) {
      this.setState({ customersOptions: [] });
      this.getCustomersAPIDebounced(value);
    }
    const isInvalidValueAndCustomers = event?.target?.value?.length < 3 && this.state.customersOptions.length > 0;
    if (isInvalidValueAndCustomers) {
      this.clearCustomerFieldSearch();
    }
  }

  getCustomersAPIDebounced = (value) => debounce(this.getCustomerstAPI(value), 500);


  getCustomerstAPI = (text) => {
    PersonApiInvoker.getCustomersOptions(text, data => {
      const customersOptions = this.formatCustomers(data);
      this.setState({ customersOptions });
    }, (error) => console.error('** error getCustomersOptions', error));
  }

  onFocusCustomerFilter(event) {
    event.persist();
    if (!(this.state?.filter?.customerId)) {
      this.clearCustomerFieldSearch();
    }
  }


  clearCustomerFieldSearch() {
    this.setState((prevState) => (
      {
        ...prevState,
        filter: {
          ...prevState.filter,
          customerId: '',
        },
        filterShow: {
          ...prevState.filterShow,
          customerId: '',
        },
        customersOptions: [],
      }
    ));
  }

  formatCustomers = (customers) => {
    let result = customers.map((c) => {
      return {
        id: c.personId,
        value: `${c.lastName} ${c.firstName}`,
        customerId: c.id,
      };
    });
    return result;
  };

  setCustomersOptions = () => {
    PersonApiInvoker.getCustomersMobility(false,(response) => {
      this.setState({ customersOptions: this.formatCustomers(response) });
    });
  };

  componentWillMount() {
    this.setCustomersOptions();
    this.getAppointments("", "", "", "", "", "", "", "", "");
    this.getFinanciers();
  }

  


  getAcceptRejectItems = (appointmentId) => {
    const { appointments } = this.state;
    const selectedAppointment = appointments.find((a) => a.appointmentId === appointmentId);
    this.setState({
      appointmentId,
      selectedAppointment,
      showItemRequest: true,
      showConfirmAcceptItemRequest: false,
      showConfirmRejectItemRequest: false,
    });
  }

  reduceAppointmentes(appointments) {
    if (appointments.length) {
      
      const data = appointments.map(this.appDataFormater);
     
      this.setState({ appointments: data });
    }
  }


  openCancelAppointment = (appointmentId) => {
    this.setState({ selectedCancelAppointment: appointmentId },
      () => {
        this.setState({ showConfirmCancelAppointment: true });
      }
    );
  }

  cancelAppointmentAction = () => {
    const body = {
      "reason": "Cancell Created appointment",
      "appointmentDetailIds": [],
    }
    AppointmentApiInvoker.cancelTransferAppointment(this.state.selectedCancelAppointment, body, () => {
      this.setState({ showConfirmationCancelAppointment: true, showConfirmCancelAppointment: false })
    }, () => {
      this.setState({ showConfirmationCancelAppointment: true, showConfirmCancelAppointment: false })
    });
  }


  formatFinanciers = (financiers) => {
    const result = [];
    financiers.map((f) => {
      result.push({
        id: f.financierId,
        value: f.businessName,
      });
    });
    return result;
  };

  appDataFormater = (a) => {
   
    const d = new Date();
    if (a.startDate == "") {
      a.startDate = d.setDate(d.getDate() - 5);
      a.endDate = d.setDate(d.getDate() + 30);
    }
    return {
      appointmentId: a.transferId,
      date: moment(a.createdDateTime).format(dateFormat),
      name: a.customer
        ? a.customer?.lastName + " " + a.customer?.firstName
        : a.customer?.name,
      customerId: a.customer?.id,
      identification: a.customer?.id,
      startDate: moment(a.startDate).format(dateFormat),
      endDate: moment(a.endDate).format(dateFormat),
      appStatus: this.setStatusAppointment(a.transferStatus),
      actions: this.renderActionComponent(a),
    };
  };

  setStatusAppointment = (status) => {
    const { transferStatus } = this.state;
    const match = transferStatus.find((item) => item.id === status);
    return match ? match.value : null;
  };


  renderActionComponent = (a, index) => {
    const { onSelectAppointmentList, t } = this.props;
    const label =
      a.appointmentStatus === "CREATED"
        ? t("appointment.asignSchedule")
        : t("appointment.viewAppointment");
    return (
      <div className="appointments-actions">
        <Tooltip title={label}>
          <div>
            <Button
              simple
              justIcon
              color="primary"
              authority="transfer_view,transfer_view_related"
              id={`button-view-${index}`}
              onClick={() => {                
                
                onSelectAppointmentList(a.transferId);
                browserHistory.push({
                  state: { mode: 'view' },
                  pathname: `/solicitudes-traslado/${a.transferId}`
                });
              }}
            >
              <Assignment />
            </Button>
          </div>
        </Tooltip>
        
        {
        (Authority.hasPermission('transfer_edit_agenda') && (a.transferStatus === 'TRANSFER_SCHEDULES_ASSIGNED' || a.transferStatus === 'PARTIAL_ASSIGNED')) &&
          <Tooltip title={t('appointment.editCalendar')}>
            <span>
              <Button
                simple
                justIcon
                color="success"
                authority="transfer_edit_agenda"
                id={`button-edit-${index}`}
                onClick={() => {
                  
                  browserHistory.push({
                    state: { mode: 'edit_agenda', authority: 'appointment_edit_agenda' },
                    pathname: `/transferappointments/${a.transferId}/edit/agenda`
                  });
                }}
              ><HealthCalendar /></Button>
            </span>
          </Tooltip>
          }
        {
        actionValidations({validation: "AcceptRejectItemRequest", a})&&
        <Tooltip title={t('appointment.actions.acceptRejectTransfer')}>
          <span>
            <Button
              simple
              justIcon
              color="primary"
              authority="mobility_appointment_item_request_acept_reject"
              id={`button-items-${index}`}
              onClick={() => this.getAcceptRejectItems(a.transferId)}
            >
              <span className="icon-with-overlay">
                <ViewListIcon className="principal-icon" />
                <DoneIcon className="overlay-icon" />
              </span>
            </Button>
          </span>
        </Tooltip>
        }
        {Authority.hasPermission('mobility_transfer_edit') && (this.state.company_partnership_id == null && a.transferStatus !== 'CREATED' && a.transferStatus !== 'CANCELLED' && a.transferStatus !== 'ENDED')
          && a.appointmentStatus !== 'OVERDUE' &&
          <Tooltip title={t('appointment.actions.edit')}>
            <span>
              <Button
                simple
                justIcon
                color="success"
                authority="mobility_transfer_edit"
                id={`button-edit-app-${index}`}
                onClick={() => {
                  onSelectAppointmentList(a.transferId);
                  browserHistory.push({
                    state: { mode: 'edit' },
                    pathname: `/transferappointments/${a.transferId}/edit/agenda`
                  });
                }}
              ><Edit /></Button>
            </span>
          </Tooltip>
        }
          
        {Authority.hasPermission('mobility_transfer_edit') && (this.state.company_partnership_id == null && a.transferStatus === 'CREATED' ) && (
          <>
            <Tooltip title={t('appointment.actions.edit')}>
              <span>
                <Button
                  simple
                  justIcon
                  color="success"
                  authority="mobility_transfer_edit"
                  id={`button-edit-agenda-${index}`}
                  onClick={() => {
                    onSelectAppointmentList(a.transferId);
                    browserHistory.push({
                      state: { mode: 'edit' },
                      pathname: `/transferappointments/${a.transferId}/edit/agenda`,
                    });
                  }}
                ><Edit /></Button>
              </span>
            </Tooltip>
            {Authority.hasPermission('mobility_transfer_cancel') && this.state.company_partnership_id == null &&
              <Tooltip title={t('appointment.actions.cancel')}>
                <span>
                  <Button
                    simple
                    justIcon
                    color="danger"
                    authority="mobility_transfer_cancel"
                    id={`button-cancel-${index}`}
                    onClick={() => this.openCancelAppointment(a.transferId)}
                  ><DeleteDocument className="svg-cancel-agenda" /></Button>
                </span>
              </Tooltip>
            }
          </>
        )}

        {Authority.hasPermission('mobility_transfer_assign') && 
          a.items?.some(item => item.transferItemRequestStatus === 'APPROVED') && 
          (a.transferStatus === 'CREATED' || a.transferStatus === 'PARTIAL_ASSIGNED') && (
            <Tooltip title={t('appointment.actions.assignAgenda')}>
              <span>
                <Button
                  simple
                  justIcon
                  color="violet"
                  authority="mobility_transfer_assign"
                  id={`button-list-${index}`}
                  onClick={() => {
                    this.props.onSelectAppointmentList(a.transferId)
                    browserHistory.push({
                      state: { mode: 'assign_agenda' },
                      pathname: `/transferappointments/${a.transferId}/edit/agenda`,
                    })
                  }}
                >
                  <HealthCalendar className="svg-assign-agenda" />
                </Button>
              </span>
            </Tooltip>
        )}
        {Authority.hasPermission('mobility_transfer_cancel') && (a.transferStatus !== 'CANCELLED' && a.transferStatus !== 'CREATED' &&
          a.transferStatus !== 'OVERDUE' && a.transferStatus !== 'ENDED' && this.state.company_partnership_id == null) &&
          <Tooltip title={t('appointment.actions.cancel')}>
            <span>
              <Button
                simple
                justIcon
                color="danger"
                authority="mobility_transfer_cancel"
                id={`button-cancel-app-${index}`}
                onClick={() => {
                  browserHistory.push({
                    pathname: `/transferappointments/${a.transferId}/cancel`,
                  });
                }}
              ><DeleteDocument className="svg-cancel-agenda" /></Button>
            </span>
          </Tooltip>
        }
        
      </div>
    );
  };

  handleValue(value, state) {
    this.setState({ [state]: value }, () => {});
  }

  addAppointment() {
    browserHistory.push("/nueva-solicitud-traslado");
  }

  handleValueCustomer(value, state) {
    const { chipsFilters, filter, filterShow, customersOptions } = this.state;
    const chips = chipsFilters;
    filterShow[state] = value;
    filter[state] = value;
    this.setState({ filter, chipsFilters: chips, filterShow }, () => {});

    if (state === 'customerId') {
      let v = customersOptions.filter(e => e.id == value);
      if (v.length > 0) {
        this.setState({ filterCustomer: v[0].id });
      }
    }

    if (state === 'customerId' && !value) {
      this.setState({ filterCustomer: [] });
    }
  }
  

  sortDate = (a, b) => {
    const a1 = moment(a, dateFormat).valueOf();
    const b1 = moment(b, dateFormat).valueOf();
    if (a1 < b1) return -1;
    else if (a1 > b1) return 1;
    else return 0;
  };

  toggleCopy = () => {
    this.setState((prevState) => ({
      ...prevState,
      dialogCopyApointment: !prevState.dialogCopyApointment,
      busy: false,
    }));
  };

  onChangeItemsRequest = (changeRequests) => this.setState({ changeRequests });

  toggleItemRequest = () => {
    this.setState({ showItemRequest: false });
    if (this.state.changeRequests) {
      this.setState({ loading: true, loadingSearch: true, appointments: [] }, () => {
        this.getAppointments();
      });
    }
  }

  getFinanciers = () => {
    BusinessApiInvoker.getFinanciers(
      (data) => {
        this.setState({ financers: this.formatFinanciers(data) });
      },
      (err) => {
        this.setState({ financers: [] });
      }
    );
  };

  perfomSuccessReload = () => {
    this.setState((prevState) => ({
      filter: prevState.filterShow,
      loading: true,
      page: 0,
      appointments: [],
      showConfirmationCancelAppointment: false,
    }), () => {
      this.getAppointments();
    });
  }


  checkAppointment = () => {
    this.setState({ busy: true });
    AppointmentApiInvoker.getAppointment(
      this.state.appointment_copy,
      (data) => {
        //mlg
        this.props.onSelectAppointmentList(-1);
        this.props.onSelectAppointmentObject(null);
        browserHistory.push({
          state: { mode: "copy" },
          pathname: `/appointments/${this.state.appointment_copy}/renew/agenda`,
        });
      },
      (err) => {
        this.setState((prevState) => ({
          ...prevState,
          dialogCopyApointment: !prevState.dialogCopyApointment,
          alertWarningCopy: true,
          busy: false,
        }));
      }
    );
  };

  cleanFilters = () => {
    this.setState({
      filterAppointmentId: "",
      filterCustomer: "",
      filterIdentification: "",
      filterState: "",
      transferFinancier: "",
      filterStartDate: "",
      filterEndDate: "",
      filterDriver: "",
      filterPersonId: "",
    });

    this.getAppointments("", "", "", "", "", "", "", "", "");
  };

  validateSearsh = (
    transferId,
    startDate,
    endDate,
    transferStatus,
    customerId,
    driverId,
    transferFinancier,
    id
  ) => {
    if (
      transferId == "" &&
      startDate == "" &&
      endDate == "" &&
      transferStatus == "" &&
      customerId == "" &&
      driverId == "" &&
      transferFinancier == "" &&
      id == ""
    ) {
      this.setState({ showSnackBar: true });
      setTimeout(() => {
        this.setState({ showSnackBar: false });
      }, 2000);
    }
  };

  getAppointments = (
    transferId,
    startDate,
    endDate,
    transferStatus,
    customerId,
    driverId,
    transferFinancier,
    id
  ) => {
    let filter = {
      transferId,
      startDate,
      endDate,
      transferStatus,
      customerId,
      driverId,
      transferFinancier,
      id
    };
    let params = Object.keys(filter)
      .filter(key => filter[key] !== "" && filter[key] !== undefined)
      .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(filter[k])}`)
      .join("&");

  params = `${params}&page=${this.state.page}&pageSize=${20}`;

  if (params) {
    params = `${params}`;
  }

  this.setState({ loading: true });

    AppointmentApiInvoker.getAppointmentsMobility(
      params,
      (data) => {
        this.setState({dataOrigin: data})
        this.reduceAppointmentes(data);
       
        this.setState({ loading: false });
        if (data.length === 0) {
          this.setState({ openMessage: true });
          setTimeout(() => {
            this.setState({ openMessage: false });
          }, 2000);
        }
      },
      (error) => {
        if (error) {
          this.reduceAppointmentes(this.state?.dataOrigin);
         
          this.setState({ loading: false });
          
          this.setState({ openMessage: true });
          setTimeout(() => {
            this.setState({ openMessage: false });
          }, 2000);
        }
      }
    );
  };

  render() {
    const { t } = this.props;
    const { alertWarningCopy, appointments, loading, showConfirmationCancelAppointment,showConfirmCancelAppointment, 
      selectedCancelAppointment,selectedAppointment, showItemRequest } = this.state;
    return (
      <GridContainer className="appointments appointments-list">
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader
              icon
              className="filters-header"
              style={{ paddingBottom: 20 }}
            >
              <div className="header-internment-table">
                <div className="header-icon">
                  <CardIcon color="warning">
                    <Assignment />
                  </CardIcon>
                </div>
                <div className="header-buttons">
                {Authority.isFinancierUser() && (
                    <Tooltip title={t('appointment.create')}>
                      <span>
                        <Button
                          className="add-content-button appointments-button"
                          round
                          color="primary"
                          authority="appointment_new"
                          onClick={() => this.addAppointment()}
                        >
                          <Add className="appointments-button-icon" />
                          {t('appointment.create')}
                        </Button>
                      </span>
                    </Tooltip>
                  )}

                </div>
              </div>

              {
                <Grid container className="appointments-filters">
                  <Grid
                    container
                    item
                    xs={12}
                    sm={9}
                    md={10}
                    className="appointments-filters-row"
                  >
                    <Snackbar
                      place="tr"
                      color={"danger"}
                      message={
                        t("common.notFoundByFilters")
                      }
                      open={this.state.openMessage}
                    />
                    <Grid container item xs={12} className="filters-row">
                      <Grid container item xs={12} sm={3}>
                        <ValidationInput
                          className="filter-full no-padding"
                          text={t("appointment.find.appointmentId")}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          type="number"
                          value={this.state.filterAppointmentId}
                          onChangeValue={(e) =>
                            this.setState({
                              filterAppointmentId: e,
                            })
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        
                        <SelectInput
                          ref={this.myRefCustomerId}
                          className="no-padding"
                          label={t("appointment.new.customer.customer")}
                          elements={this.state.customersOptions}
                          value={this.state.filterCustomer}
                          onSelectedValue={(value) => this.handleValueCustomer(value, 'customerId')}
                          onInputChange={(ev) => this.handleCustomerOptions(ev)}
                          freeSolo={true}
                          isWaitLetters={true}
                          getOptionSelected={option => option?.id || null}
                          onFocus={(ev) => this.onFocusCustomerFilter(ev)}
                          id="select-customerId"
                        />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <ValidationInput
                          className="filter-full no-padding"
                          text={t("appointment.find.identification")}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          value={this.state.filterPersonId}
                          onChangeValue={(e) =>
                            this.setState({
                              filterPersonId: e,
                            })
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <SelectInput
                          margin={true}
                          className="select-input-filters"
                          label={t("filters.appointmentStatus")}
                          elements={this.state.transferStatus}
                          value={this.state.filterState}
                          onSelectedValue={(value) =>
                            this.handleValue(value, "filterState")
                          }
                          
                        />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <DateInput
                          text={t("appointment.find.startDate")}
                          value={this.state.filterStartDate}
                          onChangeValue={(e) =>
                            this.setState({
                              filterStartDate: moment(e).format('YYYY-MM-DD'),
                            })
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <DateInput
                          text={t("appointment.find.endDate")}
                          value={this.state.filterEndDate}
                          onChangeValue={(e) =>
                            this.setState({
                              filterEndDate: moment(e).format('YYYY-MM-DD'),
                            })
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    sm={3}
                    md={2}
                    className="filters-actions-appointments"
                  >
                    <Grid
                      container
                      item
                      className="filters-row"
                    >
                      <Grid item className="filters-actions" xs={12}>
                        <Snackbar
                          color={"warning"}
                          message={
                            t("common.mustEnterSomeFilter")
                          }
                          open={this.state.showSnackBar}
                          place="tc"
                        />
                        <ComponentDisabledWithTooltip
                          tooltipText={t('dashboard.filters.search')}
                          disabled={false}
                          loading={false}
                          component={
                            <ButtonSpinner
                              onClick={() => {
                                this.validateSearsh(
                                  this.state.filterAppointmentId,
                                  this.state.filterStartDate,
                                  this.state.filterEndDate,
                                  this.state.filterState,
                                  this.state.filterDriver,
                                  this.state.filterCustomer,
                                  this.state.filterFinancier,
                                  this.state.filterPersonId
                                );
                                this.getAppointments(
                                  this.state.filterAppointmentId,
                                  this.state.filterStartDate,
                                  this.state.filterEndDate,
                                  this.state.filterState,
                                  this.state.filterCustomer,
                                  this.state.filterDriver,
                                  this.state.filterFinancier,
                                  this.state.filterPersonId,
                                );
                              }}
                              label={t("appointment.find.find")}
                              labelLoading={"Buscar"}
                              typeButton="submit"
                              color="primary"
                              block
                            />
                          }
                        />
                      </Grid>
                      <Grid item className="filters-actions" xs={12}>
                        <Button
                          block
                          onClick={this.cleanFilters}
                          color="danger"
                        >
                          {t("appointment.find.clean")}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              }
            </CardHeader>

            <CardBody className="appointments-content-body not-scroll-table filtrable-table">
              <Table
                filterable
                loading={loading}
                tableHeaderColor={"primary"}
                defaultPageSize={appointments.length > 10 ? 20 : 10}
                sortable
                tableHead={[
                  {
                    Header: t("appointment.table.appointment_id"),
                    accessor: "appointmentId",
                  },
                  {
                    Header: t("appointment.table.init_date"),
                    accessor: "date",
                    sortMethod: (a, b) => this.sortDate(a, b),
                  },
                  {
                    Header: t("label.identification"),
                    accessor: "identification",
                  },
                  {
                    Header: t("appointment.table.customer.name"),
                    accessor: "name",
                  },
                  {
                    Header: t("appointment.table.start_date"),
                    accessor: "startDate",
                    sortMethod: (a, b) => this.sortDate(a, b),
                  },
                  {
                    Header: t("appointment.table.end_date"),
                    accessor: "endDate",
                    sortMethod: (a, b) => this.sortDate(a, b),
                  },
                  {
                    Header: t("appointment.table.status"),
                    accessor: "appStatus",
                  },
                  {
                    Header: t("appointment.table.actions"),
                    accessor: "actions",
                    sortable: false,
                    filterable: false,
                  },
                ]}
                tableData={this.state.appointments}
                colorsColls={["primary"]}
                className="appointments-component -striped -highlight filtrable sticky"
                showPaginationBottom={true}
                showPaginationTop={false}
              />
            </CardBody>
          </Card>
        </GridItem>

        <CustomDialog
          title={t("appointment.renew.title")}
          maxWidth={"sm"}
          onClose={() => this.toggleCopy()}
          open={this.state.dialogCopyApointment}
        >
          <div>
            <p className="appointmentRenewTitle">
              {t("appointment.renew.question")}
            </p>
            <div className="booking-errors-actions">
              <CustomInput
                id="appointment_copy"
                className="filter-number"
                formControlProps={{
                  fullWidth: false,
                }}
                type="number"
                onChange={(e) =>
                  this.setState({ appointment_copy: e.target.value })
                }
                inputProps={{
                  autocomplete: "no",
                }}
              />
            </div>
          </div>
          <div className="content-actions">
            <ButtonSpinner
              onClick={() => this.checkAppointment()}
              disabled={this.state.busy}
              label={t("appointment.renew.acept")}
              labelLoading={t("common.saving")}
              loading={this.state.busy}
              typeButton="submit"
              color="primary"
            />
          </div>
        </CustomDialog>

        <CustomDialog
          maxWidth="md"
          open={showItemRequest}
          onClose={() => this.toggleItemRequest()}
          title={t('appointment.actions.acceptRejectTransfer')}
        >
          <ItemsRequest
            appointment={selectedAppointment}
            
            packages={this.state.package}
            onChangeItemsRequest={(a) => this.onChangeItemsRequest(a)}
          />
        </CustomDialog>

        {alertWarningCopy && (
          <SweetAlert
            warning
            style={{ display: "block", marginTop: "-100px" }}
            onConfirm={() =>
              this.setState({
                dialogCopyApointment: true,
                alertWarningCopy: false,
              })
            }
            confirmBtnCssClass={"btn primary"}
            confirmBtnText={t("appointment.new.accept")}
            showCancel={false}
          >
            <p>{t("appointment.renew.renew_warning")}</p>
          </SweetAlert>
        )}
        
        <Snackbar
          place={this.state.snackbar.place}
          color={this.state.snackbar.color}
          message={this.state.snackbar.message}
          open={this.state.snackbar.open}
          onClose={() => 
            this.setState(prevState => ({
              snackbar: { ...prevState.snackbar, open: false }
            }))
          }
        />

        {showConfirmCancelAppointment &&
          <CustomSweetAlert
            type="warning"
            confirmBtnCssClass="primary"
            cancelBtnCssClass="danger"
            onConfirm={this.cancelAppointmentAction}
            onCancel={() => this.setState({ showConfirmCancelAppointment: false })}
            title={t('appointment.created.cancel.title')}
            cancelBtnText={t('appointment.created.cancel.cancel')}
            showCancel={true}
            message={<p>{t('appointment.created.cancel.message')} {selectedCancelAppointment}</p>}
            confirmBtnText={t('appointment.created.cancel.confirm')}
          />
        }
         {showConfirmationCancelAppointment &&
          <CustomSweetAlert
            onConfirm={this.perfomSuccessReload}
            confirmBtnCssClass="primary"
            title={t('appointment.created.cancel.confirmation.title')}
            type="success"
            confirmBtnText={t('appointment.created.cancel.confirmation.OK')}
            message={<p>{selectedCancelAppointment} {t('appointment.created.cancel.confirmation.message')}</p>}
            showCancel={false}
          />
        }
      </GridContainer>
    );
  }
}

TransferAppointments.defaultProps = {
  appointments: [],
};

TransferAppointments.propTypes = {
  t: PropTypes.func,
  onSelectAppointmentList: PropTypes.func.isRequired,
  onSelectAppointmentObject: PropTypes.func.isRequired,
  appointments: PropTypes.any,
};

export default withTranslation()(TransferAppointments);
