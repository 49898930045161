import {
  ValidationType,
  Appointment,
  ValidationFunctions,
} from '../interfaces/validationsInterfaces'
import Authority from '../../../util/Authority'

const acceptRejectValidation = (a: Appointment): boolean => {

  if(a.transferStatus === "ENDED" || a.transferStatus === 'CANCELLED'){
    return false
  }
  let userCompany: string | number
  if(Authority.isFinancierUser()){
    userCompany = localStorage.getItem('itlg_default_company_id') ?? ""
  }else if(Authority.isProviderUser()){
    userCompany = localStorage.getItem('company_partnership_id') ?? ""
  }
  
  const hasItemsToAssign = a.items?.some(
    (item) =>
      item.transferItemRequestStatus === 'CREATED' &&
      item.companyIdPartnership.companyId === Number(userCompany)
  )
  return (
    Authority.hasPermission('mobility_appointment_item_request_acept_reject') &&
    hasItemsToAssign
  )
}

export const actionValidations = ({
  validation,
  a,
}: {
  validation: ValidationType
  a: Appointment
}): boolean => {
  const validations: ValidationFunctions = {
    AcceptRejectItemRequest: acceptRejectValidation,
  }

  return validations[validation](a)
}
