import './Appointments.css';

import {
  Assignment as AssignmentIcon,
  DeleteDocument,
  EventAcceptedTentatively,
  EventDeclined,
  HealthCalendar,
  ViewList as ViewListIcon,
} from '../../icons';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, Tooltip, debounce } from '@material-ui/core';
import React, { Component } from 'react';

import Add from '@material-ui/icons/Add';
import ApiInvoker from '../../api/ApiInvoker';
import AppointmentApiInvoker from '../../api/AppointmentApiInvoker';
import AppointmentItemsDetailModal from './AppointmentItemsDetailModal';
import Assignment from '@material-ui/icons/Assignment';
import Authority from '../../util/Authority';
import Button from '../CustomButtons/Button';
import ButtonSpinner from '../ButtonSpinner';
import Card from '../Card/Card';
import CardBody from '../Card/CardBody';
import CardHeader from '../Card/CardHeader';
import CardIcon from '../Card/CardIcon';
import ComponentDisabledWithTooltip from '../ComponentDisabledWithTooltip';
import Copy from '@material-ui/icons/FileCopy';
import CustomDialog from '../CustomDialog/index';
import CustomInput from '../CustomInput/CustomInput';
import CustomSweetAlert from '../CustomSweetAlert';
import DateInput from '../DateInput';
import DnsOutlinedIcon from '@material-ui/icons/DnsOutlined';
import DoneIcon from '@material-ui/icons/Done';
import Edit from '@material-ui/icons/Edit';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import { InfoOutlined } from '@material-ui/icons';
import ItemsRequest from './ItemsRequest';
import PersonApiInvoker from '../../api/PersonApiInvoker';
import PropTypes from 'prop-types';
import SelectInput from '../SelectInput';
import Table from '../TableFull/Table';
import { browserHistory } from 'react-router';
import config from '../../config/config';
import moment from 'moment';
import { withTranslation } from 'react-i18next';

const dateFormat = config.getDateFormat();
const dateFormatServer = config.getDateToServer();

class AppointmentsPractice extends Component {
  constructor(props) {
    super(props);
    this.processItemsRequest = this.processItemsRequest.bind(this);

    const { t } = props;
    const proximityList = [
      { id: -1, value: '' },
      { id: 1, value: t('appointment.table.filters.red') },
      { id: 2, value: t('appointment.table.filters.yellow') },
      { id: 3, value: t('appointment.table.filters.green') },
    ];
    const scheduleStatusList = [
      { id: -1, value: '' },
      { id: 'PENDING_APPROVAL', value: t('scheduleDetail.state.PENDING_APPROVAL') },
      { id: 'REJECTED', value: t('scheduleDetail.state.REJECTED') },
      { id: 'APPROVED', value: t('scheduleDetail.state.APPROVED') },
      { id: 'IN_PROGRESS', value: t('scheduleDetail.state.IN_PROGRESS') },
      { id: 'DONE', value: t('scheduleDetail.state.DONE') },
      { id: 'NOT_ATTENDED', value: t('scheduleDetail.state.NOT_ATTENDED') },
      { id: 'WITHOUT_ATTENTION', value: t('scheduleDetail.state.WITHOUT_ATTENTION') },
      { id: 'CANCELLED', value: t('scheduleDetail.state.CANCELLED') },
      { id: 'FINISHED_SYSTEM', value: t('scheduleDetail.state.FINISHED_SYSTEM') }
    ];
    const customerStatusList = [
      { id: '', value: '' },
      { id: 'active', value: t('customer.active') },
      { id: 'inactive', value: t('customer.inactive') }
    ];
    const requestStatusList = [
      { id: '', value: '' },
      { id: 'active', value: t('customer.active') },
      { id: 'inactive', value: t('customer.inactive') }
    ]
    const appointmentStatusList = [
      { id: '', value: '' },
      { id: 'CREATED', value: t('appointment.table.status.CREATED') },
      { id: 'SCHEDULES_ASSIGNED', value: t('appointment.table.status.SCHEDULES_ASSIGNED') },
      { id: 'PARTIAL_ASSIGNED', value: t('appointment.table.status.PARTIAL_ASSIGNED') },
      { id: 'CANCELED', value: t('appointment.table.status.CANCELLED') },
      { id: 'ENDED', value: t('appointment.table.status.ENDED') },
      { id: 'OVERDUE', value: t('appointment.table.status.OVERDUE') },
      { id: 'APROVEED', value: t('status.appointmentItemRequest.APROVEED') },
      { id: 'REJECT', value: t('scheduleDetail.state.REJECTED') }
    ];

    const dayAndWeekList = [
      { id: '', value: '' },
      { id: 'DAY', value: t('recurrenceTypes.day') },
      { id: 'INTRADAY', value: t('recurrenceTypes.intraday') },
      { id: 'WEEK', value: t('recurrenceTypes.week') },
      { id: 'MONTH', value: t('recurrenceTypes.month') },
    ];

    this.appointmentStatusList = [
      { id: '', value: '' },
      { id: 'CREATED', value: t('appointment.table.status.CREATED') },
      { id: 'SCHEDULES_ASSIGNED', value: t('appointment.table.status.SCHEDULES_ASSIGNED') },
      { id: 'PARTIAL_ASSIGNED', value: t('appointment.table.status.PARTIAL_ASSIGNED') },
      { id: 'CANCELED', value: t('appointment.table.status.CANCELLED') },
      { id: 'ENDED', value: t('appointment.table.status.ENDED') },
      { id: 'OVERDUE', value: t('appointment.table.status.OVERDUE') },
      { id: 'APROVEED', value: t('status.appointmentItemRequest.APROVEED') },
      { id: 'REJECT', value: t('scheduleDetail.state.REJECTED') },
    ];

    this.dayAndWeekList = [
      { id: '', value: '' },
      { id: 'DAY', value: t('recurrenceTypes.daily') },
      { id: 'WEEK', value: t('recurrenceTypes.weekly') },
      { id: 'MONTH', value: t('recurrenceTypes.monthly') },
      { id: 'INTRADAY', value: t('recurrenceTypes.intraday') },
    ];

    this.state = {
      practiceTypes: [],
      isPracticeTypesLoaded: false,
      suppliers: [],
      supplierNames: [],
      selectedSupplierName: '',
      practiceAppointments: [],
      appointmentsBackup: [],
      isDataLoaded: false,
      practiceItemsRequest: [],
      page: 0,
      dayAndWeekList,
      showItemRequest: false,
      loading: false,
      loadingSearch: false,
      pageSize: 25,
      pageSizeOriginal: 25,
      scheduleStatusList,
      appointmentStatusList,
      appointmentStatusPractice: [
        { id: '', value: '' },
        { id: 'CREATED', value: t('appointment.table.status.CREATED') },
        { id: 'APROVEED', value: t('status.appointmentItemRequest.APROVEED') },
        { id: 'REJECT', value: t('status.appointmentItemRequest.REJECTED') },
        { id: 'SCHEDULES_ASSIGNED', value: t('appointment.table.status.SCHEDULES_ASSIGNED') },
        { id: 'CANCELED', value: t('appointment.table.status.CANCELLED') },
      ],
      customerStatusList,
      proximityList,
      filterModules: '',
      filterProfessionals: '',
      filterStatus: '',
      id: '',
      appointmentId: '',
      filterTools: '',
      dateFrom: '',
      practiceFilter: {
        practiceTypeId: '',
        affiliateId: '', 
      },
      dateTo: '',
      currentFilterColor: [],
      company_partnership_id: null,
      color: 'true',
      checkedInput: false,
      currentUserId: localStorage.getItem('user_id'),
      dialogCopyApointment: false,
      busy: false,
      chips: [],
      originalquery: [],
      showFilters: false,
      showPractices: false,
      selectedAppointmentId: null,
      chipsFilters: [],
      chips_original: [],
      practiceFilterShow: {
        practiceTypeId: '',
        affiliateId: '',
      },
      customersList: [],
      selectedAppointment: {},
      changeRequests: false,
      customerNameSelected: null,
      selectedCancelAppointment: null,
      third_parties: [],
      lender: null,
      creatorUsers: [],
      selectedCreatorUser: null,
      isInactivateModalOpen: false,
      inactivateStartDate: null,
      inactivateEndDate: null,
      inactivationStartDate: null,
      inactivationEndDate: null,
      appointmentStartDate: null,
      appointmentEndDate: null,
      isActiveAppointment: null,
      endDateError: '',
      requestStatusList,
      loadingInactive: false,
    }
    this.myRefStartDate = React.createRef();
    this.myRefEndDate = React.createRef();
    this.myRefCustomerId = React.createRef();
  }

  processItemsRequest = () => {
    const { practiceItemsRequest: itemsRequest, page, pageSize } = this.state;
    const processedAppointments = itemsRequest
      .slice(0, (page + 1) * pageSize)
      .map(this.practiceAppDataFormater);
    this.setState({ practiceAppointments: processedAppointments });
  }

  componentWillMount() {
    const company_partnership_id = localStorage.getItem('company_partnership_id');
    const is_patient = localStorage.getItem('is_patient') == 'true';
    this.setState({ company_partnership_id, is_patient });
    this.getCookies();
    this.getPracticeTypes();
    this.getModuleTypes();
    this.getProvincies();
    this.getPartnership();
    this.getCreatorUsers();
  }

  getPracticeTypes() {
    if (!this.state.isPracticeTypesLoaded) {
      AppointmentApiInvoker.getPracticeTypes(data => {
        this.setState({ 
          practiceTypes: this.formatPracticeTypes(data),
          isPracticeTypesLoaded: true
        }, () => {
          if (this.state.practiceFilter.practiceTypeId) {
            this.getPartnership();
          }
        });
      }, null);
    }
  }

  formatPracticeTypes = (practiceTypes) => {
    return practiceTypes.map(pt => ({
      id: pt.practiceTypeId,
      value: pt.name,
      estimatedDuration: pt.estimatedDuration,
    }));
  }

  getToolSuppliersData() {
    if (this.state.supplierNames.length === 0) {
      ApiInvoker.getToolSuppliers(data => {
        if(data) {
          const suppliers = this.formatSuppliers(data);
          this.setState({
            suppliers,
            supplierNames: suppliers.map(s => ({ id: s.id, value: s.name }))
          })
        }
      }, null);
    }
  }

  formatSuppliers(data) {
    return data.map(d => ({
      id: d.productSuppliersId,
      name: d.name,
      code: d.code
    }));
  }


  getModuleTypes() {
    AppointmentApiInvoker.getPackagesAll(data => {
      if (data.length) {
        this.setState({ package: data });
      }
    }, null);
  }

  componentWillUnmount() {
    this.props.onGetAppointments([]);
  }

  getAcceptRejectPracticeItems = (appointmentId) => {
    const { practiceAppointments: appointments } = this.state;
    const selectedAppointment = appointments.find((a) => a.appointmentId === appointmentId);
    this.setState({
      appointmentId,
      selectedAppointment,
      showItemRequest: true,
      showConfirmAcceptItemRequest: false,
      showConfirmRejectItemRequest: false,
    });
  }

  buildParamsGetAppointments(load) {
    console.log("buildParamsGetAppointments called with load:", load);
    let { practiceFilter: filter, page, pageSize, pageSizeOriginal } = this.state;
    console.log("Current filter:", filter);
  
    if (load && page !== 0) {
      pageSize = pageSizeOriginal * (page);
      page = 0;
    }
  
    const filterParams = [
      'appointmentId',
      'customerId',
      'startDate',
      'endDate',
      'customerStatus',
      'scheduleStatus',
      'lender',
      'practiceTypeId',
      'appointmentStatusPractice',
      'selectedSupplierName',
      'creatorUserId',
      'affiliateId', 
      'activeAppointment',
    ];
  
    const params = filterParams
      .filter(key => filter[key] != null && filter[key] !== '')
      .map(key => {
        let value = filter[key];
        let paramKey = key;
  
        switch(key) {
          case 'startDate':
          case 'endDate':
            value = moment(value).format(dateFormatServer);
            break;
          case 'customerStatus':
            paramKey = 'activeCustomers';
            value = value === 'active' ? 'true' : 'false';
            break;
          case 'lender':
            paramKey = 'companyIdPartnership';
            break;
          case 'appointmentStatusPractice':
            paramKey = 'practiceStatus';
            break;
          case 'selectedSupplierName':
            paramKey = 'productSuppliersId';
            break;
          case 'creatorUserId':
            paramKey = 'personId';
            break;
            case 'activeAppointment':
              value = value === 'active' ? 'true' : 'false';
              break;
          default:
            break;
        }
  
        return `${encodeURIComponent(paramKey)}=${encodeURIComponent(value)}`;
      })
      .filter(Boolean) 
      .join('&');
  
    const paginationParams = `page=${page}&pageSize=${pageSize}`;
    const limitParam = 'limit=200';
    
    return params 
      ? `${params}&${paginationParams}` 
      : `${paginationParams}&${limitParam}`;
  }

  getPracticeAppointments = (load) => {
    const { practiceAppointments } = this.state;
    this.setState({ loading: true });
    const params = this.buildParamsGetAppointments(load);
    
    AppointmentApiInvoker.getItemsRequestFilter(params, data => {
      this.setState({ loading: false, loadingSearch: false });
      if (data) {
        // Procesamos los datos sin ordenarlos
        const processedData = data.map(item => {
          try {
            let processedItem = { ...item };
            
            if (typeof item.appointmentItemRequestStatus === 'string' && 
                !item.appointmentItemRequestStatus.includes('_')) {
              const statusCode = this.getStatusCode(item.appointmentItemRequestStatus);
              processedItem.appointmentItemRequestStatus = statusCode;
            }
            return processedItem;
          } catch (error) {
            console.error('Error procesando item:', error);
            return item;
          }
        });
  
        if (!load) {
          const combinedData = [...practiceAppointments.map(item => {
            return {
              ...item,
              _rawStartDate: item.startDate,
              _rawEndDate: item.endDate,
              _rawCreatedDateTime: item.createdDateTime
            };
          }), ...processedData];
          
          // Removemos el sort
          this.setState({ practiceItemsRequest: combinedData }, () => {
            this.reducePracticeAppointments(combinedData, false);
          });
        } else {
          // Removemos el sort
          this.setState({ practiceItemsRequest: processedData }, () => {
            this.reducePracticeAppointments(processedData, true);
          });
        }
      } else {
        this.setState({ practiceItemsRequest: [], practiceAppointments: [] });
      }
    }, (error) => {
      console.error('** error getItemsRequestFilter', error);
      this.setState({ loading: false, loadingSearch: false });
    });
  };

  getPracticeItemRequest = (appointmentId) => {
    this.setState({ showPractices: true, selectedAppointmentId: appointmentId });
  }

  renderPracticeEndDate(daysLeft, rejected, schedulesPending, appointmentStatus) {
    const { t } = this.props;
  
    const renderTooltip = (title, className) => (
      <>
        <Tooltip className="toolTipEdit" title={title}>
          <span><AssignmentIcon className={`${className} svgIcon`} /></span>
        </Tooltip>
        {rejected}
        {schedulesPending}
      </>
    );
  
    switch (true) {
      case daysLeft >= 6 && daysLeft <= 15:
        return renderTooltip(`${t('appointment.table.remaining')} ${daysLeft} ${t('appointment.table.days')}`, "warning");
      case daysLeft <= 5 && daysLeft > 0:
        return renderTooltip(`${t('appointment.table.remaining')} ${daysLeft} ${(daysLeft === 1) ? t('appointment.table.day') : t('appointment.table.days')}`, "danger");
      case daysLeft === 0:
        return renderTooltip(t('appointment.table.remaining.today'), "danger");
      case appointmentStatus === 'SCHEDULES_ASSIGNED' && daysLeft < 0:
        return renderTooltip(t('appointment.table.remaining.end'), "default");
      default:
        return <>{rejected}{schedulesPending}</>;
    }
  }

  getAppointmentStatus = (a, t, notFoundMessage = t('recurrenceTypes.status.not.found')) => {
    if (!a?.appointmentItemRequestStatus) {
      return notFoundMessage;
    }
  
    const statusToCheck = a.appointmentItemRequestStatus;
    
    // Si el estado ya está traducido, lo retornamos directamente
    const isAlreadyTranslated = this.appointmentStatusList.some(
      status => status.value === statusToCheck
    );
    
    if (isAlreadyTranslated) {
      return statusToCheck;
    }
  
    // Buscar por ID
    const status = this.appointmentStatusList.find(
      status => status.id === statusToCheck
    );
  
    if (status?.value) {
      return status.value;
    }
  
    return notFoundMessage;
  };

  getAppointmentStatusDAY(item, t, notFoundMessage = t('recurrenceTypes.not.found')) {
    const status = this.dayAndWeekList.find(status => status.id === item.recurrenceType);
    if (!status) return notFoundMessage;
      let recurrenceText = status.value;
    switch (item.recurrenceType) {
      case 'DAY':
        recurrenceText += ` / ${t( item?.quantity > 1 ? 'label.recurrence.day.every_plural' : 'label.recurrence.day.every', {every: item.quantity})}`;
        break;
      case 'WEEK':
        const daysOfWeek = item.weekDays?.split(',').map(day => this.getDayName(day, t)).join(', ');
        recurrenceText += ` / ${t( item?.quantity > 1 ? 'label.recurrence.week.every_plural' : 'label.recurrence.week.every',{every: item?.quantity})} / ${t('label.recurrence.days', {days:daysOfWeek })}`;
        break;
      case 'MONTH':
        recurrenceText += ` / ${t(item?.quantity === 1 ? 'label.recurrence.month.every' : 'label.recurrence.month.every_plural',{every: item?.quantity})}`;
        if (item.monthDay) {
          recurrenceText += ` / ${t('label.recurrence.days', {days:item?.monthDay})}`;
        }
        break;  
      case 'INTRADAY':
        const visitsPerDay = ` / ${t(item?.quantity > 1 ? 'label.recurrence.intraday.numberAppo_plural' : 'label.recurrence.intraday.numberAppo',{number: item?.quantity})}`;
        const intradayDays = item.weekDays?.split(',').map(day => this.getDayName(day, t)).join(', ');
        recurrenceText += `${visitsPerDay} /  ${t('label.recurrence.days', {days:intradayDays })}`;
        break;
  
      default:
        return notFoundMessage;
    }
  
    return recurrenceText;
  }
  
  getDayName(dayAbbreviation, t) {
    const dayMap = {
      'L': t('day.monday'),
      'M': t('day.tuesday'),
      'X': t('day.wednesday'),
      'J': t('day.thursday'),
      'V': t('day.friday'),
      'S': t('day.saturday'),
      'D': t('day.sunday')
    };
    return dayMap[dayAbbreviation] || dayAbbreviation;
  }

  practiceAppDataFormater = (a, index) => {
    const { t } = this.props;
    
    const getBasicInfo = (a) => {
      const translatedStatus = this.getAppointmentStatus(a, t);
      
      return {
        employee: { personId: a?.responsableEmployee?.personId },
        appointmentItemRequestStatus: translatedStatus,
        appointmentId: a.appointmentId,
        affiliateId: a.affiliateId,
        date: this.formatDate(a.createdDateTime || a.startDate, dateFormat, t('common.invalidDate')),
        createdDateTime: a.createdDateTime || a.startDate,
        customerId: a.customerId || `${a.customerFirstName} ${a.customerLastName}`,
        name: a.name || `${a.customerLastName} ${a.customerFirstName}`,
      };
    };
  
    const getAppointmentStatus = (a) => ({
      hasSchedulesRejected: a.hasSchedulesRejected || false,
      hasItemsPending: a.hasItemsPending || (a.appointmentStatus === 'CREATED'),
      hasItemsConfirmed: a.hasItemsConfirmed || (a.appointmentStatus !== 'CREATED'),
      appStatus: t(`appointment.table.status.${a.appointmentStatus}`) || t('appointment.table.status.DEFAULT'),
    });
  
    const getLocationInfo = (a) => ({
      locationName: a.locationName || '',
      provinceName: a.provinceName || '',
      financierName: a.companyFinancier || a.partnershipName,
    });

    const getDates = (a) => {
      const { t } = this.props;
      const invalidDateMessage = t('appointment.input.dateinvalid');
      
      try {
        // Si las fechas ya están procesadas (_raw), las usamos directamente
        if (a._rawStartDate) {
          return {
            startDate: a._rawStartDate,
            endDate: a._rawEndDate,
            compareEndDate: moment(a._rawEndDate),
            createdDateCompare: a._rawCreatedDateTime
          };
        }
    
        // Si no, procesamos las fechas normalmente
        return {
          startDate: this.formatDate(a.startDate),
          endDate: this.formatDate(a.endDate),
          compareEndDate: moment(a.endDate),
          createdDateCompare: this.formatDate(a.createdDateTime || a.startDate, dateFormatServer)
        };
      } catch (error) {
        console.error('Error formateando fechas:', error);
        return {
          startDate: invalidDateMessage,
          endDate: invalidDateMessage,
          compareEndDate: null,
          createdDateCompare: null
        };
      }
    };
  
    const getPracticeInfo = (a) => ({
      practiceTypeName: a.practiceTypeName,
      codPractice: a.codPractice,
      partnerCode: a.partnerCode,
      partnershipName: a.partnershipName,
      toolId: a.toolId || '',
      recurrenceType: a.recurrenceType || '',
      quantity: a.quantity,
      suppliersCode: a.suppliersCode,
      suppliersName: a.suppliersName,
      toolSerialNumber: a.toolSerialNumber || '',
      frecuencia: `${this.getAppointmentStatusDAY(a, t)}`,
    });
  
    return {
      ...getBasicInfo(a),
      ...getAppointmentStatus(a),
      ...getLocationInfo(a),
      ...getDates(a),
      ...getPracticeInfo(a),
      appointmentDateState: this.practiceEndDateFormater(a.endDate, a.hasSchedulesRejected, a.hasSchedulesPending, a.appointmentStatus),
      actions: this.renderPracticeActionComponent(a, index),
    };
  };

  getStatusCode = (translatedStatus) => {
    const statusMap = {
      'Aceptada': 'APROVEED',
      'Rechazada': 'REJECT',
      'Creada': 'CREATED',
      'Agenda asignada': 'SCHEDULES_ASSIGNED',
      'Agenda parcialmente asignada': 'PARTIAL_ASSIGNED',
      'Cancelada': 'CANCELED',
      'Finalizada': 'ENDED',
      'Vencida': 'OVERDUE'
    };
    return statusMap[translatedStatus] || translatedStatus;
  };

  reducePracticeAppointments = (appointments, isInitialLoad) => {
    if (appointments?.length) {
      const data = appointments.map((app, index) => {
        if (app._rawStartDate && !isInitialLoad) {
          return {
            ...this.practiceAppDataFormater(app, index),
            startDate: app._rawStartDate,
            endDate: app._rawEndDate,
            createdDateTime: app._rawCreatedDateTime
          };
        }
        return this.practiceAppDataFormater(app, index);
      });
  
      // Removemos la deduplicación por appointmentId
      let size = ((this.state.page + 1) * this.state.pageSize);
      let filteredData = data.slice(0, size);
  
      this.setState({ 
        practiceAppointments: filteredData, 
        originalquery: filteredData 
      });
    }
  };

//
getCreatorUsers = () => {
  AppointmentApiInvoker.getEmployeesWithPractices(
    (data) => {
      const formattedUsers = this.formatCreatorUsers(data);
      console.log("Formatted creator users:", formattedUsers);
      this.setState({ creatorUsers: formattedUsers });
    },
    (error) => {
      console.error('Error fetching creator users:', error);
    }
  );
}

  formatCreatorUsers = (users) => {
    return users.map(user => ({
      id: user.personId,
      value: `${user.lastName} ${user.firstName}`
    }));
  }

  handleCreatorUserChange = (value) => {
    console.log("handleCreatorUserChange called with value:", value);
    this.setState(prevState => ({
      practiceFilter: { ...prevState.practiceFilter, creatorUserId: value },
      practiceFilterShow: { ...prevState.practiceFilterShow, creatorUserId: value },
      selectedCreatorUser: value
    }), () => {
      console.log("State after handleCreatorUserChange:", this.state.practiceFilter);
    });
  }

  getPartnership = () => {
    AppointmentApiInvoker.getCompanyPartnershipModules(data => {
      const third_parties = this.buildPartnershipData(data);
      this.setState({ third_parties });
    }, () => {
      this.setState({ third_parties: [] });
    });
  }

  buildPartnershipData = (data) => {
    const partnership = data.map(p => ({
      id: p.companyIdPartnership.companyId,
      value: p.companyIdPartnership.name,
    }));
  
    const id = parseInt(localStorage.getItem('itlg_default_company_id'));
    const value = localStorage.getItem('itlg_default_company_name');
    const partnershipArray = [{ id, value }, ...partnership];
    return partnershipArray;
  }

  handleModalClose = () => {
    this.setState({
      isInactivateModalOpen: false,
      inactivateStartDate: null,
      inactivateEndDate: null,
      selectedAppointmentId: null,
      startDateError: '',
      endDateError: '',
    });
  }

  updateInactivationStartDate = (selectedDate) => {
    const { t } = this.props;
      if (!selectedDate || !moment(selectedDate).isValid()) {
        this.setState(() => ({ 
            inactivateStartDate: null, 
            startDateError: '' 
        }));
        return;
    }

    const dateToValidate = moment(selectedDate).startOf('day');
    const appointmentStart = moment(this.state.appointmentStartDate).startOf('day');
    const appointmentEnd = moment(this.state.appointmentEndDate).startOf('day');
    
    let errorMessage = '';
    
    if (dateToValidate.isBefore(appointmentStart)) {
      errorMessage = t('inactive.errorBeforeStartDate');
    } else if (dateToValidate.isAfter(appointmentEnd)) {
      errorMessage = t('inactive.errorAfterStartDate');
    }

    this.setState(prevState => ({
        inactivateStartDate: dateToValidate.toDate(),
        startDateError: errorMessage,
        inactivateEndDate: errorMessage ? prevState.inactivateEndDate : null,
        endDateError: errorMessage ? prevState.endDateError : ''
    }));
  }

  processInactivationEndDate = (selectedDate) => {
    const { t } = this.props;
      const updateStateWithError = (date, errorMessage) => {
        this.setState(() => ({
            inactivateEndDate: date,
            endDateError: errorMessage,
        }));
    };

    if (!selectedDate || !moment(selectedDate).isValid()) {
        const errorMessage = this.state.isActiveAppointment ? 
            '' : 
            t('inactive.errorRequiredEndDate');
        
        updateStateWithError(null, errorMessage);
        return;
    }

    const proposedEndDate = moment(selectedDate).startOf('day');
    const inactivationStart = moment(
        this.state.inactivateStartDate || 
        this.state.inactivationStartDate
    ).startOf('day');
    const appointmentEnd = moment(this.state.appointmentEndDate).startOf('day');

    let errorMessage = '';

    if (proposedEndDate.isBefore(inactivationStart)) {
      errorMessage = t('inactive.errorBeforeEndDate');
    } else if (proposedEndDate.isAfter(appointmentEnd)) {
      errorMessage = t('inactive.errorAfterEndDate');
    }

    updateStateWithError(proposedEndDate.toDate(), errorMessage);
  }

  validateInactivationDate = (date) => {
    if (!date) return false;
    const targetDate = moment(date);
    const periodStart = moment(this.state.appointmentStartDate);
    const periodEnd = moment(this.state.appointmentEndDate);
    
    return !targetDate.isBetween(periodStart, periodEnd, 'day', '[]');
  }
  
  isAcceptButtonGoEnabled = () => {
    const { 
      inactivateStartDate, 
      inactivateEndDate, 
      inactivationStartDate, 
      inactivationEndDate, 
      isActiveAppointment,
      startDateError,
      endDateError
    } = this.state;
    
    const startDate = inactivateStartDate || inactivationStartDate;
    const endDate = inactivateEndDate || inactivationEndDate;
  
    if (!startDate || startDateError || endDateError) return false;
    
    if (isActiveAppointment) {
      return true; // Si está activa, solo se requiere fecha de inicio sin errores
    } else {
      // Si está inactiva o es null, se requieren ambas fechas sin errores
      return startDate && endDate;
    }
  }

  handleConfirmationAccept = () => {
    const { selectedAppointmentId, inactivateStartDate, inactivateEndDate, currentUserId } = this.state;
    this.setState({ loadingInactive: true });
  
    AppointmentApiInvoker.patchAppointmentInactivation(
      selectedAppointmentId,
      inactivateStartDate,
      inactivateEndDate,
      currentUserId,
      (response) => {
        this.setState({ 
          isInactivateModalOpen: false, 
          showConfirmationDialog: false,
          loadingInactive: false, 
        });
        this.getAppointments();
      },
      (error) => {
        console.error('Error al actualizar la inactivación', error);
        this.setState({ loadingInactive: false });
      }
    );
  }
  
  renderPracticeActionComponent = (a, index) => {
    const { onSelectAppointmentList, t } = this.props;
    return (
      <div className="appointments-actions">
        <Tooltip title={t('appointment.viewAppointment')}>
          <span>
            <Button
              simple
              justIcon
              color="primary"
              authority="appointment_view,appointment_view_related"
              id={`button-view-${index}`}
              onClick={() => {
                onSelectAppointmentList(a.appointmentId);
                browserHistory.push({
                  state: { mode: 'view' },
                  pathname: `/solicitudes/${a.appointmentId}`
                });
              }}
            ><Assignment /></Button>
          </span>
        </Tooltip>
        {(Authority.hasPermission('appointment_edit_agenda') && (a.appointmentStatus === 'SCHEDULES_ASSIGNED' || a.appointmentStatus === 'PARTIAL_ASSIGNED')) &&
          <Tooltip title={t('appointment.editCalendar')}>
            <span>
              <Button
                simple
                justIcon
                color="success"
                authority="appointment_edit_agenda"
                id={`button-edit-${index}`}
                onClick={() => {
                  onSelectAppointmentList(a.appointmentId);
                  browserHistory.push({
                    state: { mode: 'edit', authority: 'appointment_edit_agenda' },
                    pathname: `/appointments/${a.appointmentId}/edit/agenda`
                  });
                }}
              ><HealthCalendar /></Button>
            </span>
          </Tooltip>
        }

        {Authority.hasPermission('appointment_item_request_acept_reject') && ((a.appointmentStatus === 'CREATED' || a.appointmentStatus === 'PARTIAL_ASSIGNED') &&
          a.hasItemsPending === true) &&
          <Tooltip title={t('appointment.actions.acceptReject')}>
            <span>
              <Button
                simple
                justIcon
                color="primary"
                authority="appointment_item_request_acept_reject"
                id={`button-items-${index}`}
                onClick={() => this.getAcceptRejectPracticeItems(a.appointmentId)}
              >
                <span className="icon-with-overlay">
                  <ViewListIcon className="principal-icon" />
                  <DoneIcon className="overlay-icon" />
                </span>
              </Button>
            </span>
          </Tooltip>
        }

        {Authority.hasPermission('appointment_edit') && (this.state.company_partnership_id == null && a.appointmentStatus !== 'CREATED' && a.appointmentStatus !== 'CANCELLED' && a.appointmentStatus !== 'ENDED')
          && a.appointmentStatus !== 'OVERDUE' &&
          <Tooltip title={t('appointment.actions.edit')}>
            <span>
              <Button
                simple
                justIcon
                color="success"
                authority="appointment_edit"
                id={`button-edit-app-${index}`}
                onClick={() => {
                  onSelectAppointmentList(a.appointmentId);
                  browserHistory.push({
                    state: { mode: 'edit' },
                    pathname: `/solicitudes/${a.appointmentId}`
                  });
                }}
              ><Edit /></Button>
            </span>
          </Tooltip>
        }
        {Authority.hasPermission('appointment_edit') && (this.state.company_partnership_id == null && a.appointmentStatus === 'CREATED') && (
          <>
            <Tooltip title={t('appointment.actions.edit')}>
              <span>
                <Button
                  simple
                  justIcon
                  color="success"
                  authority="appointment_edit"
                  id={`button-edit-agenda-${index}`}
                  onClick={() => {
                    onSelectAppointmentList(a.appointmentId);
                    browserHistory.push({
                      state: { mode: 'edit' },
                      pathname: `/appointments/${a.appointmentId}/edit/agenda`,
                    });
                  }}
                ><Edit /></Button>
              </span>
            </Tooltip>
            {Authority.hasPermission('appointment_cancel') && this.state.company_partnership_id == null &&
              <Tooltip title={t('appointment.actions.cancel')}>
                <span>
                  <Button
                    simple
                    justIcon
                    color="danger"
                    authority="appointment_cancel"
                    id={`button-cancel-${index}`}
                    onClick={() => this.redirectToCancelView(a.appointmentId)}
                  ><DeleteDocument className="svg-cancel-agenda" /></Button>
                </span>
              </Tooltip>
            }
          </>
        )}
        {Authority.hasPermission('appointment_assign') && ((a.appointmentStatus === 'CREATED' || a.appointmentStatus === 'PARTIAL_ASSIGNED') &&
          a.hasItemsConfirmed === true) &&
          <Tooltip title={t('appointment.actions.assignAgenda')}>
            <span>
              <Button
                simple
                justIcon
                color="violet"
                authority="appointment_assign"
                id={`button-list-${index}`}
                onClick={() => {
                  this.props.onSelectAppointmentList(a.appointmentId)
                  browserHistory.push({
                    state: { mode: 'assign_agenda' },
                    pathname: `/appointments/${a.appointmentId}/edit/agenda`,
                  })
                }}
              ><HealthCalendar className="svg-assign-agenda" /></Button>
            </span>
          </Tooltip>
        }
        {Authority.hasPermission('appointment_cancel') && (a.appointmentStatus !== 'CANCELLED' && a.appointmentStatus !== 'CREATED' &&
          a.appointmentStatus !== 'OVERDUE' && a.appointmentStatus !== 'ENDED' && this.state.company_partnership_id == null) &&
          <Tooltip title={t('appointment.actions.cancel')}>
            <span>
              <Button
                simple
                justIcon
                color="danger"
                authority="appointment_cancel"
                id={`button-cancel-app-${index}`}
                onClick={() => this.redirectToCancelView(a.appointmentId)}
              ><DeleteDocument className="svg-cancel-agenda" /></Button>
            </span>
          </Tooltip>
        }
        {Authority.hasPermission('end_schedule_by_system') && ((a.appointmentStatus === 'SCHEDULES_ASSIGNED' || a.appointmentStatus === 'OVERDUE') &&
          this.state.company_partnership_id == null) &&
          <Tooltip title={t('schedule.finished.title')}>
            <span>
              <Button
                simple
                justIcon
                color="danger"
                authority="end_schedule_by_system"
                id={`button-finished-system-${index}`}
                onClick={() => {
                  browserHistory.push({
                    state: { mode: 'view', action: "finished_system" },
                    pathname: `/solicitudes/${a.appointmentId}`,
                  });
                }}
              ><EventAvailableIcon className="svg-cancel-agenda" /></Button>
            </span>
          </Tooltip>
        }
        
        <Tooltip title={t('appointment.actions.list.practice')}>
          <span>
            <Button
              simple
              justIcon
              color="success"
              id={`button-request-${index}`}
              onClick={() => {
                this.getPracticeItemRequest(a.appointmentId);
              }}
            ><DnsOutlinedIcon /></Button>
          </span>
        </Tooltip>
      </div>
    )
  }

  formatPracticeCustomers = (customers) => {
    if (!customers.length) {
      return [];
    }
    return customers.map(e => ({
      id: e.personId,
      value: `${e.lastName} ${e.firstName}`,
    })).sort((a, b) => a.value.localeCompare(b.value));
  }

  getPracticeCustomersAPIDebounced = (value) => debounce(this.getPracticeCustomersAPI(value), 500);

  handlePracticeValue(value, state) {
    this.setState(prevState => ({
      practiceFilter: { ...prevState.practiceFilter, [state]: value },
      practiceFilterShow: { ...prevState.practiceFilterShow, [state]: value },
    }), () => {
      if (state === 'customerId') {
        let v = this.state.customersList.filter(e => e.id == value);
        if (v.length > 0) {
          this.setState({ customerNameSelected: v[0].value });
        }
      }
      if (state === 'practiceTypeId' && value) {
        this.getPartnership();
      }
    });
  }

  addPracticeAppointment = () => {
    browserHistory.push('/nueva-solicitud');
  }


  getPracticeCustomersAPI = (text) => {
    PersonApiInvoker.getCustomersOptions(text, this.handleCustomersOptionsResponse, this.handleCustomersOptionsError);
  }
  
  handleCustomersOptionsResponse = (data) => {
    const customersList = this.formatPracticeCustomers(data);
    this.setState({ customersList });
  }
  
  handleCustomersOptionsError = (error) => {
    console.error('** error getCustomersOptions', error);
  }
  
  clearPracticeCustomerFieldSearch = () => {
    this.setState(this.getEmptyCustomerFieldState());
  }
  
  getEmptyCustomerFieldState = () => (prevState) => ({
    ...prevState,
    practiceFilter: {
      ...prevState.practiceFilter,
      customerId: '',
    },
    practiceFilterShow: {
      ...prevState.practiceFilterShow,
      customerId: '',
    },
    customersList: [],
  })
  
  handlePracticeCustomerOptions = (event) => {
    if (event?.target?.value == undefined) return;
    
    const value = event.target.value;

    const isValue = (event?.target) && (value && value.length >= 3);
    if (!event) {
      this.clearPracticeCustomerFieldSearch();
    }
    if (isValue) {
      this.setState({ customersList: [] });
      this.getPracticeCustomersAPIDebounced(value);
    }
    const isInvalidValueAndCustomers = event?.target?.value?.length < 3 && this.state?.customersList?.length >= 0;
    if (isInvalidValueAndCustomers) {
      this.clearPracticeCustomerFieldSearch();
    }
  }
  
  onFocusPracticeCustomerFilter = (event) => {
    event.persist();
    if (!this.state?.practiceFilter?.customerId) {
      this.clearPracticeCustomerFieldSearch();
    }
  }

  togglePracticeCopy = () => {
    this.setState(this.getToggledCopyState);
  }
  
  getToggledCopyState = (prevState) => ({ 
    dialogCopyApointment: !prevState.dialogCopyApointment, 
    busy: false 
  })
  
  checkPracticeAppointment = () => {
    const { appointment_copy } = this.state;
    this.setState({ busy: true });
    AppointmentApiInvoker.getAppointment(
      appointment_copy,
      this.handleSuccessfulAppointmentCheck,
      this.handleFailedAppointmentCheck
    );
  }
  
  handleSuccessfulAppointmentCheck = () => {
    this.props.onSelectAppointmentList(-1);
    this.props.onSelectAppointmentObject(null);
    this.navigateToRenewAgenda();
  }
  
  handleFailedAppointmentCheck = () => {
    this.setState(prevState => ({ 
      dialogCopyApointment: !prevState.dialogCopyApointment, 
      alertWarningCopy: true, 
      busy: false 
    }));
  }
  
  navigateToRenewAgenda = () => {
    browserHistory.push({
      state: { mode: 'copy' },
      pathname: `/appointments/${this.state.appointment_copy}/renew/agenda`,
    });
  }
  
  cancelAppointmentAction = () => {
    const body = {
      reason: "Cancel Created appointment",
      appointmentDetailIds: [],
    };
    AppointmentApiInvoker.cancelAppointment(
      this.state.selectedCancelAppointment, 
      body, 
      this.handleCancelAppointmentResponse,
      this.handleCancelAppointmentResponse
    );
  }
  
  handleCancelAppointmentResponse = () => {
    this.setState({ 
      showConfirmationCancelAppointment: true, 
      showConfirmCancelAppointment: false 
    });
  }
  
  redirectToCancelView = (appointmentId) => {
    browserHistory.push(this.getCancelViewPath(appointmentId));
  }
  
  getCancelViewPath = (appointmentId) => ({
    pathname: `/appointments/${appointmentId}/cancel`,
  });

  formatProvincies = (provincies) => provincies.map(this.mapProvince);

  mapProvince = (province) => ({
    id: province.provinceId,
    value: province.name,
  });

  getProvincies = () => {
    ApiInvoker.getAllProvinces(
      this.handleProvincesSuccess,
      this.handleProvincesError
    );
  }

  getCookies = () => {
    //const cookies = new Cookies();
    const practiceFilter = config.getFilterForLocalStorage("items-request-filter");
    const page = this.getPageFromCookies();
    
    if (practiceFilter) {
      this.handleExistingPracticeFilter(practiceFilter, page);
    } else {
      this.handleNoPracticeFilter(page);
    }
  }
  
  getPageFromCookies = () => {
    let page = config.getFilterForLocalStorage("items-request-filter_page");
    return page ? parseInt(page) : 0;
  }
  
  handleExistingPracticeFilter = (practiceFilter, page) => {
    const patientName = config.getFilterForLocalStorage("appointment_patient_practice_name");
    if (patientName) {
      const truncatedPatientName = patientName.slice(0,4)
      this.getPracticeCustomersAPI(truncatedPatientName);
    }
    this.setState({
      practiceFilter,
      practiceFilterShow: practiceFilter,
      customerNameSelected: patientName,
      lender: practiceFilter?.lender || null,
      selectedCreatorUser: practiceFilter?.creatorUserId || null,
      page
    }, () => this.getPracticeAppointments(true));
  }
  
  handleNoPracticeFilter = (page) => {
    this.setState({ page }, () => this.getPracticeAppointments(true));
  }

  practiceEndDateFormater = (endDate, hasSchedulesRejected, hasSchedulesPending, appointmentStatus) => {
    const { t } = this.props;
    const now = moment();
    const endDateF = moment(endDate);
    
    const getDaysLeft = () => {
      if (['SCHEDULES_ASSIGNED', 'CREATED'].includes(appointmentStatus)) {
        return Math.ceil(endDateF.diff(now, 'days', true));
      }
      return -1;
    };
  
    const getTooltip = (condition, titleKey, IconComponent, className) => {
      if (condition) {
        return (
          <Tooltip className="toolTipEdit" title={t(titleKey)}>
            <span><IconComponent className={`${className} svgIcon`} /></span>
          </Tooltip>
        );
      }
      return null;
    };
  
    const daysLeft = getDaysLeft();
    const rejected = getTooltip(hasSchedulesRejected, 'appointment.table.rejected', EventDeclined, 'danger');
    const schedulesPending = getTooltip(hasSchedulesPending, 'appointment.table.pending', EventAcceptedTentatively, 'warning');
  
    return this.renderPracticeEndDate(daysLeft, rejected, schedulesPending, appointmentStatus);
  }

  sortPracticeDate = (a, b) => {
    const invalidDateMessage = this.props.t('appointment.input.dateinvalid');
  
    // Si alguna de las fechas es inválida o es el mensaje de fecha inválida, la colocamos al final
    if (a === invalidDateMessage && b === invalidDateMessage) return 0;
    if (a === invalidDateMessage) return 1;
    if (b === invalidDateMessage) return -1;
  
    try {
      const a1 = moment(a, dateFormat);
      const b1 = moment(b, dateFormat);
  
      if (!a1.isValid() && !b1.isValid()) return 0;
      if (!a1.isValid()) return 1;
      if (!b1.isValid()) return -1;
  
      return a1.valueOf() - b1.valueOf();
    } catch (error) {
      console.error('Error en sortPracticeDate:', error);
      return 0;
    }
  };

  formatDate = (date, format = dateFormat) => {
    const invalidDateMessage = this.props.t('appointment.input.dateinvalid');
    
    try {
      if (!date) return invalidDateMessage;
      const momentDate = moment(date);
      if (!momentDate.isValid()) return invalidDateMessage;
      return momentDate.format(format);
    } catch (error) {
      console.error('Error en formatDate:', error);
      return invalidDateMessage;
    }
  };

  onChangePracticeItemsRequest = (changeRequests) => this.setState({ changeRequests });

  togglePracticeItemRequest = () => {
    this.setState({ showItemRequest: false });
    if (this.state.changeRequests) {
      this.setState({ loading: true, loadingSearch: true, practiceAppointments: [] }, () => {
        this.getPracticeAppointments();
      });
    }
  }
  
  practiceFiltersSearch = () => {
    this.props.onCleanAppointments();
    this.setState((prevState) => {
      const newPracticeFilter = {
        ...prevState.practiceFilter,
        creatorUserId: prevState.practiceFilter.creatorUserId
      };
      return {
        practiceFilter: newPracticeFilter,
        loading: true,
        loadingSearch: true,
        page: 0,
        practiceAppointments: [],
        practiceItemsRequest: [],
      };
    }, () => {
      //let cookies = new Cookies();
      config.setFilterForLocalStorage("items-request-filter", this.state.practiceFilter);
      config.setFilterForLocalStorage("appointment_patient_practice_name", this.state.customerNameSelected);
      //cookies.set("appointment_patient_practice_name", this.state.customerNameSelected);
      this.getPracticeAppointments();
    });
  }
  showMorePractices = () => {
    this.setState((prev) => ({
      page: prev.page + 1,
      loading: true,
    }), () => {
      config.setFilterForLocalStorage("items-request-filter_page", this.state.page);
      this.getPracticeAppointments(false);
    });
  };

  cleanPracticeSearch = () => {
    this.props.onCleanAppointments();
    this.setState({
      practiceFilter: {},
      practiceFilterShow: {},
      page: 0,
      loading: false,
      loadingSearch: false,
      customersList: [],
      practiceAppointments: [],
      practiceItemsRequest: [],
      customerNameSelected: null,
      lender: null,
      third_parties: [],
      selectedCreatorUser: null,
      creatorUsers: [],
    }, () => {
      config.removeFilterForLocalStorage("items-request-filter");
      config.removeFilterForLocalStorage("items-request-filter_page");
      this.getPracticeAppointments();
      this.myRefStartDate.current.handleBlur('');
      this.myRefEndDate.current.handleBlur('');
      this.getPartnership();
      this.getCreatorUsers();
    });
  }

  performPracticeSuccessReload = () => {
    this.setState((prevState) => ({
      practiceFilter: prevState.practiceFilterShow,
      loading: true,
      page: 0,
      practiceAppointments: [],
      showConfirmationCancelAppointment: false,
    }), () => {
      this.getPracticeAppointments();
    });
  }

  render() {
    const { t } = this.props;
    const {
      alertWarningCopy, practiceAppointments,appointmentStatusPractice,
      busy, company_partnership_id, customersList, dialogCopyApointment, practiceFilter: filter, practiceFilterShow: filterShow,
      loading, loadingSearch, loadingInactive, practiceTypes, third_parties, lender, requestStatusList, selectedAppointment,
      scheduleStatusList, selectedCancelAppointment, showConfirmCancelAppointment, showConfirmationCancelAppointment, showItemRequest,
    } = this.state;
    let finacier = (company_partnership_id == null);
    return (
      <Grid container className="appointments appointments-list">
        <Grid item xs={12}>
          <Card>
            <CardHeader icon className="filters-header" style={{ paddingBottom: 20 }}>
              <CardIcon color="secondary">
                <Assignment />
              </CardIcon>
              <Grid container justifyContent="flex-end">
                <Grid item xs={12} className="buttons-header appointment-header">
                  {company_partnership_id == null && !this.state.is_patient &&
                    <Tooltip title={t('appointment.new.newappointment')}>
                      <span>
                        <Button
                          className="add-button"
                          round
                          color="primary"
                          authority="appointment_new"
                          id="button-new-appo"
                          onClick={() => this.addPracticeAppointment()}
                        >
                          <Add className="appointments-button-icon" /> {t('appointment.create')}
                        </Button>
                      </span>
                    </Tooltip>
                  }
                  {company_partnership_id == null && !this.state.is_patient &&
                    <Tooltip title={t('appointment.copy')}>
                      <span>
                        <Button
                          className="copy-button"
                          round
                          color="warning"
                          authority="appointment_new"
                          id="button-copy-appo"
                          onClick={() => this.togglePracticeCopy()}
                        >
                          <Copy className="appointments-button-icon" />
                        </Button>
                      </span>
                    </Tooltip>
                  }
                </Grid>
              </Grid>

              <Grid container className="appointments-filters">
                <Grid container item xs={12} sm={9} md={10} className="appointments-filters-row">
                  <Grid container item xs={12} className="filters-row first">
                    <Grid container item xs={12} sm={3}>
                      <Grid item xs={6}>
                        <CustomInput
                          className="filter-full no-padding"
                          labelText={t('appointment.find.appointmentId')}
                          formControlProps={{
                            fullWidth: true
                          }}
                          value={filter.appointmentId !== undefined ? filter.appointmentId : ''}
                          onChange={(value) => this.handlePracticeValue(value.target.value, 'appointmentId')}
                          errorText={t('error.required')}
                          id="input-appointmentId"
                        />
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <CustomInput
                          className="filter-full no-padding"
                          labelText={t('affiliate.number')}
                          formControlProps={{
                            fullWidth: true
                          }}
                          value={filter.affiliateId != undefined ? filter.affiliateId : ''}
                          onChange={(value) => this.handlePracticeValue(value.target.value, 'affiliateId')}
                          errorText={t('error.required')}
                          id="input-affiliateId"
                        />
                      </Grid>
                    </Grid>
                    
                    {/* Fecha de fin */}
                    <Grid item xs={12} sm={3}>
                      <SelectInput
                        ref={this.myRefCustomerId}
                        className="no-padding"
                        label={t('appointment.find.customer')}
                        elements={customersList}
                        value={filter.customerId}
                        onSelectedValue={(value) => this.handlePracticeValue(value, 'customerId')}
                        onInputChange={(ev) => this.handlePracticeCustomerOptions(ev)}
                        freeSolo={true}
                        isWaitLetters={true}
                        getOptionSelected={option => option?.id || null}
                        onFocus={(ev) => this.onFocusPracticeCustomerFilter(ev)}
                        id="select-customerId"
                      />
                    </Grid>


                    {/* Fecha de inico y fin */}
                    <Grid container item xs={14} sm={3}>
                      <Grid container item xs={6} className="date-container startDate">
                        <DateInput
                          ref={this.myRefStartDate}
                          text={t('dashboardeconomictool.practiceStartDate')}
                          value={filterShow.startDate}
                          onChangeValue={(value) => this.handlePracticeValue(value, 'startDate')}
                          id="datetime-start"
                        />
                      </Grid>
                      <Grid container item xs={6} className="date-container endDate">
                        <DateInput
                          ref={this.myRefEndDate}
                          text={t('dashboardeconomictool.practiceEndDate')}
                          value={filterShow.endDate}
                          onChangeValue={(value) => this.handlePracticeValue(value, 'endDate')}
                          id="datetime-end"
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid container item xs={12} className="filters-row">
                    <Grid item xs={12} sm={3}>
                      <SelectInput
                        className="date-filter no-padding"
                        label={t('appointment.find.practice')}
                        elements={this.state.practiceTypes}
                        value={filterShow.practiceTypeId}
                        onSelectedValue={(value) => this.handlePracticeValue(value, 'practiceTypeId')}
                        id="select-practice-type"
                        onFocus={() => this.getPracticeTypes()}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                    <SelectInput
                      className="date-filter no-padding"
                      label={t('appointment.find.customerStatus')}
                      elements={this.state.customerStatusList}
                      value={filterShow.customerStatus}
                      onSelectedValue={(value) => this.handlePracticeValue(value, 'customerStatus')}
                      id="select-appointment-status"
                      onFocus={() => this.getToolSuppliersData()}
                    />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <SelectInput
                        className="date-filter no-padding"
                        label={t('appointment.find.scheduleStatusPractice')}
                        elements={appointmentStatusPractice}
                        value={filterShow.appointmentStatusPractice}
                        onSelectedValue={(value) => this.handlePracticeValue(value, 'appointmentStatusPractice')}
                        id="select-appointment-status-practice"
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                    <SelectInput
                      className="date-filter no-padding"
                      label={t('appointment.find.user.credator')}
                      elements={this.state.creatorUsers}
                      value={this.state.selectedCreatorUser}
                      onSelectedValue={this.handleCreatorUserChange}
                      id="select-creator-user"
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <SelectInput
                      className="date-filter no-padding"
                      label={t('appointment.find.state')}
                      elements={requestStatusList}
                      value={filterShow.activeAppointment}
                      onSelectedValue={(value) => this.handlePracticeValue(value, 'activeAppointment')}
                      id="select-appointment-state"
                    />
                    </Grid>
                  {finacier &&
                    <Grid item xs={12} sm={3}>
                    <SelectInput
                      className="date-filter no-padding"
                      label={t('appointment.new.schedule.practice.lender')}
                      elements={third_parties}
                      value={lender}
                      onSelectedValue={(value) => this.handlePracticeValue(value, 'lender')}
                      id="select-lender"
                    />
                    </Grid>
                    }
                    {/* <Grid item xs={12} sm={3}>
                      <SelectInput
                        className="date-filter no-padding"
                        label={t('tools.new.title.name.proveedor')}
                        elements={this.state.supplierNames}
                        value={filterShow.selectedSupplierName}
                        onSelectedValue={(value) => this.handlePracticeValue(value, 'selectedSupplierName')}
                        id="select-supplier"
                        onFocus={() => this.getToolSuppliersData()}
                      />
                      
                    </Grid> */}
                    <Grid item xs={12} sm={3}>
                    <SelectInput
                        className="date-filter no-padding"
                        label={t('appointment.find.scheduleStatus')}
                        elements={scheduleStatusList}
                        value={filterShow.scheduleStatus}
                        onSelectedValue={(value) => this.handlePracticeValue(value, 'scheduleStatus')}
                        id="select-schedule-status"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                
                <Grid container item xs={12} sm={3} md={2} className="filters-actions-appointments">
                  <Grid container item justifyContent="flex-end" className="filters-row">
                    <Grid item xs={12} className="info-search">
                      <Tooltip aria-label="info" title={t('tooltipInfo.searchPractices')} placement="left-start">
                        <InfoOutlined />
                      </Tooltip>
                    </Grid>
                    <Grid item className="filters-actions" xs={12}>
                      <ComponentDisabledWithTooltip
                        tooltipText={t('reassignAppointments.buttonTooltip')}
                        disabled={false}
                        loading={false}
                        component={
                          <ButtonSpinner
                            id="button-search"
                            onClick={() => this.practiceFiltersSearch()}
                            label={t('appointment.find.find')}
                            labelLoading={t('appointment.find.find')}
                            loading={loadingSearch}
                            disabled={loading}
                            typeButton="submit"
                            color="primary"
                            block
                          />
                        }
                      />
                    </Grid>
                    <Grid item className="filters-actions" xs={12}>
                      <Button id="button-clean" block onClick={() => this.cleanPracticeSearch()} color="danger">
                        {t('appointment.find.clean')}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardHeader>

             <CardBody className="appointments-content-body not-scroll-table filtrable-table">
              <Table
                filterable
                loading={loading}
                tableHeaderColor="primary"
                sortable
                tableHead={[
                  { Header: t('appointment.practice.table.status.practice'), id: 'appointmentItemRequestStatus', accessor: 'appointmentItemRequestStatus'},
                  { Header: t('appointment.practice.table.afild.number'), id: 'affiliateId', accessor: 'affiliateId' },
                  { Header: t('appointment.practice.table.paciente'), id: 'customerId', accessor: 'customerId' },
                  { Header: t('appointment.practice.table.code.practices'), id: 'codPractice', accessor: 'codPractice' },
                  { Header: t('appointment.practice.table.name.practice'), id: 'practiceTypeName', accessor: 'practiceTypeName' },
                  { Header: t('appointment.practice.table.frecuencia'), id: 'frecuencia', accessor: 'frecuencia' },
                  { Header: t('appointment.practice.table.inicio.practice'), id: 'startDate', accessor: 'startDate', sortMethod: (a, b) => this.sortPracticeDate(a, b) },
                  { Header: t('appointment.practice.table.end.practice'), id: 'endDate', accessor: 'endDate', sortMethod: (a, b) => this.sortPracticeDate(a, b) },
                  { Header: t('appointment.practice.table.code.prestador'), id: 'partnerCode', accessor: 'partnerCode' },
                  { Header: t('appointment.practice.table.prestador'), id: 'partnershipName', accessor: 'partnershipName' },
                  { Header: t('appointment.table.actions'), id: 'actions', accessor: 'actions', sortable: false, filterable: false },
                ]}
                tableData={this.state.practiceAppointments}
                defaultSorted={[]} 
                colorsColls={['primary']}
                className="appointments-component -striped -highlight filtrable sticky"
                defaultPageSize={10000}
                showPaginationTop={false}
                showPaginationBottom={false}
              />
            </CardBody> 
          </Card>
        </Grid>

        {practiceAppointments.length > 0 &&
          <Grid item xs={12}>
             <ButtonSpinner
              onClick={() => this.showMorePractices()}
              label={t('appointment.showmore.praciteces')}
              labelLoading={t('appointment.showmore')}
              loading={loading}
              typeButton="submit"
              block
              disabled={loading}
              className="button-gray"
              id="button-more"
            />
          </Grid>
        }

        <CustomDialog
          title={t('appointment.renew.title')}
          maxWidth="sm"
          open={dialogCopyApointment}
          onClose={() => this.togglePracticeCopy()}
        >
          <div>
            <p className="appointmentRenewTitle">
              {t('appointment.renew.question')}
            </p>
            <div className="booking-errors-actions">
              <CustomInput
                className="filter-number"
                id="appointment_copy"
                type="number"
                formControlProps={{ fullWidth: false }}
                inputProps={{ autocomplete: "no" }}
                onChange={(e) => this.setState({ appointment_copy: e.target.value })}
              />
            </div>
          </div>
          <div className="content-actions">
            <ButtonSpinner
              onClick={() => this.checkPracticeAppointment()}
              disabled={busy}
              label={t('appointment.renew.acept')}
              labelLoading={t('common.saving')}
              loading={busy}
              typeButton="submit"
              color="primary"
              id="button-check"
            />
          </div>
        </CustomDialog>

        <CustomDialog
          maxWidth="md"
          open={showItemRequest}
          onClose={() => this.togglePracticeItemRequest()}
          title={t('appointment.actions.acceptReject')}
        >
          <ItemsRequest
            selectedAppointment={{ appointmentId: selectedAppointment.appointmentId, endDate: selectedAppointment.endDate }}
            practiceTypes={practiceTypes}
            packages={this.state.package}
            onChangeItemsRequest={(a) => this.onChangePracticeItemsRequest(a)}
          />
        </CustomDialog>

        {alertWarningCopy &&
          <CustomSweetAlert
            type="warning"
            onConfirm={() => this.setState({ dialogCopyApointment: true, alertWarningCopy: false })}
            confirmBtnCssClass="primary"
            confirmBtnText={t('appointment.new.accept')}
            showCancel={false}
            message={<p>{t('appointment.renew.renewWarning')}</p>}
          />
        }

        {this.state.showPractices &&
          <AppointmentItemsDetailModal
            appointmentId={this.state.selectedAppointmentId}
            onConfirm={(showPractices) => {
              this.setState((prevState) => ({ showPractices, selectedAppointmentId: !showPractices ? null : prevState.selectedAppointmentId })
              )
            }}
          />
        }

        {showConfirmCancelAppointment &&
          <CustomSweetAlert
            type="warning"
            onConfirm={this.cancelAppointmentAction}
            onCancel={() => this.setState({ showConfirmCancelAppointment: false })}
            confirmBtnCssClass="primary"
            cancelBtnCssClass="danger"
            title={t('appointment.created.cancel.title')}
            cancelBtnText={t('appointment.created.cancel.cancel')}
            confirmBtnText={t('appointment.created.cancel.confirm')}
            showCancel={true}
            message={<p>{t('appointment.created.cancel.message')} {selectedCancelAppointment}</p>}
          />
        }

        {showConfirmationCancelAppointment &&
          <CustomSweetAlert
            type="success"
            onConfirm={this.performPracticeSuccessReload}
            confirmBtnCssClass="primary"
            title={t('appointment.created.cancel.confirmation.title')}
            confirmBtnText={t('appointment.created.cancel.confirmation.OK')}
            showCancel={false}
            message={<p>{selectedCancelAppointment} {t('appointment.created.cancel.confirmation.message')}</p>}
          />
        }

        <Dialog open={this.state.isInactivateModalOpen} onClose={this.handleModalClose} fullWidth={true} maxWidth="sm"
        PaperProps={{
          style: {
            minHeight: '60vh',
            maxHeight: '65vh',
            borderRadius: 15,
          },
        }}>

        <DialogTitle style={{ padding: '10px', textAlign: 'center', paddingTop: '25px'}}>{t('appointment.popup.inactive')}</DialogTitle>
          <DialogContent style={{ padding: '10px' }}>
            <DateInput
              shouldDisableDate={this.validateInactivationDate}
              text={t('appointment.startDate.inactivaction')}
              value={this.state.inactivateStartDate || this.state.inactivationStartDate}
              onChangeValue={this.updateInactivationStartDate}
              minDate
              maxDateValue={this.state.appointmentEndDate}
              required
              disablePast
            />
            <DateInput
              text={t('appointment.endDate.inactivaction')}
              value={this.state.inactivateEndDate || this.state.inactivationEndDate}
              onChangeValue={this.processInactivationEndDate}
              minDateValue={this.state.inactivateStartDate || this.state.inactivationStartDate || moment().toDate()}
              maxDateValue={this.state.appointmentEndDate}
              disabled={!this.state.inactivateStartDate && !this.state.inactivationStartDate}
              required={!this.state.isActiveAppointment}
              disablePast
              shouldDisableDate={(date) => {
                const currentDate = moment(date);
                const startDate = moment(this.state.inactivateStartDate || this.state.inactivationStartDate);
                return currentDate.isBefore(startDate, 'day') || currentDate.isAfter(moment(this.state.appointmentEndDate), 'day');
              }}
            />
            {this.state.endDateError && (
              <p style={{ color: 'red', fontSize: '0.8rem', paddingLeft: '20px' }}>{this.state.endDateError}</p>
            )}
          </DialogContent>
          <DialogActions style={{ padding: 20 }}>
            <Button 
              onClick={this.handleModalClose} 
              color="danger"
            >
              {t('appointment.dialog.btn.no')} 
           </Button>

           <ButtonSpinner
              id="button-accept"
              onClick={() => this.handleInactivateConfirm()}
              label={t('appointment.dialog.btn.ok')}
              labelLoading={t('appointment.dialog.btn.ok')}
              loading={loadingInactive}
              typeButton="submit"
              color="primary"
              disabled={loadingInactive || !this.isAcceptButtonGoEnabled()}
            />
          </DialogActions>
        </Dialog >

         {/* Alerta SweetAlert para confirmar */}
         {this.state.showConfirmationDialog && (
          <CustomSweetAlert
            type="warning"
            onConfirm={this.handleConfirmationAccept}
            onCancel={() => this.setState({ showConfirmationDialog: false, loadingInactive: false })}
            title={t('accept.title.inactivacion')}
            confirmBtnCssClass={loadingInactive ? 'primary disabled' : 'primary'}
            cancelBtnCssClass="danger"
            cancelBtnText={t('appointment.created.cancel.cancel')}
            confirmBtnText={t('appointment.dialog.btn.confirmar')}
            showCancel={true}
            message={<p>{t('accept.title.inactivacion.message')}</p>}
          />
        )}
      </Grid>
   );
  }
}

AppointmentsPractice.propTypes = {
  t: PropTypes.func,
  onCleanAppointments: PropTypes.func.isRequired,
  onGetAppointments: PropTypes.func.isRequired,
  onSelectAppointmentList: PropTypes.func.isRequired,
  onSelectAppointmentObject: PropTypes.func.isRequired,
}

export default withTranslation()(AppointmentsPractice);
